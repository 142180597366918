import React from 'react';
import { Link } from 'react-router-dom';
import banner from '../assets/images/ilha-grande/banner_7.jpg';
import { Parallax, Background } from 'react-parallax';

export default function Banner2() {

    return (
        <Parallax strength={500} className="banner-area">
            <Background className="banner-area-bg">
                <Link to="/a-pousada">
                    <img src={banner} />
                </Link>
            </Background>
        </Parallax>
    );
}

