import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Fade from 'react-reveal/Fade';

import waves from '../assets/images/waves_blue.png';
import LanguageContext from '../contexts/language';

export default function Clients(props) {

    const { language } = useContext(LanguageContext);

    function Content(props){
        return (
            <div className="testimonial-item">
                <div className="testi-comment">
                    <p className="testi__desc">
                        {props.children}
                    </p>
                </div>
                <div className="testi-content">
                    <h4 className="tesi__title">{props.title}</h4>
                </div>
            </div>
        )
    }
    return (

        <section className="testimonial-area padding-top-100px padding-bottom-80px text-center">
            <div className="container">
                <Fade top>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h5 className="sec__top">{language.cliente_title}</h5>
                                <h2 className="sec__title">{language.cliente_subtitle} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                <p className="sec__desc">
                                    {language.cliente_text_1}
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="row">
                        <div className="col-lg-8 mx-auto mt-4">
                            <div className="testimonial-carousel">
                                <Carousel
                                    interval={5000}
                                    transitionTime={700}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showArrows={false}
                                    showIndicators={false}
                                    showStatus={false}
                                    showThumbs={false}
                                    stopOnHover={false}
                                    >

                                    <Content title="Isabel">
                                    {language.cliente_1}
                                    </Content>
                                    <Content title="Gustavo">
                                    {language.cliente_2}
                                    </Content>
                                    <Content title="Kelly">
                                    {language.cliente_3}
                                    </Content>
                                    <Content title="Priscilla">
                                    {language.cliente_4}
                                    </Content>
                                    <Content title="Mari">
                                    {language.cliente_5}
                                    </Content>

                                </Carousel>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>

    );
}