import React, { useContext } from 'react';
import { FaCheck } from "react-icons/fa";
import LanguageContext from '../contexts/language';

export default function Caracteristicas() {

    const { language } = useContext(LanguageContext);
    
    return (
        <div className="col-lg-4">
            <div className="sidebar section-bg">
                <div className="sidebar-widget">
                    <h3 className="widget-title text-uppercase">{language.caracteristicas}</h3>
                    <div className="title-shape"></div>
                    <div className="cat-list padding-top-30px">
                        <ul className="list-items">
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_1}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_2}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_3}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_4}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_5}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_6}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_7}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_8}</div></li>
                            <li className="mb-2 pb-2 text-uppercase"><div className="padding-right-10px"><FaCheck className="check-feature" size={20} /> {language.caracteristica_9}</div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
