import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderVideo from '../../components/HeaderVideo';
import Standard from '../../components/Standard';
import Sobre from '../../components/Sobre';

import IlhaGrande from '../../components/IlhaGrande';
import Clients from '../../components/Clients';
import NewsHome from '../../components/NewsHome';

import Banner1 from '../../components/Banner1';
import Banner2 from '../../components/Banner2';

export default function Home() {
    return (
        <div>
            <Header />
            <HeaderVideo />
            
            <Standard />
            <Banner1 />
            <Sobre />
            
            <IlhaGrande />
            <Clients />
            <Banner2 />
            <NewsHome />

            <Footer newsletter={true} />
        </div>
    );
}
