import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import api from '../services/api';
import Shimmer from './Shimmer';

import base from '../assets/images/blank-850.png';
import waves from '../assets/images/waves_blue.png';
import LanguageContext from '../contexts/language';

export default function News() {

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const lang = localStorage.getItem('language');
    const { language } = useContext(LanguageContext);

    async function loadPage(){
        const response = await api.get(`/news?take=no&lang=${lang}`);
        setNews(response.data.content);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [lang]);

    function ContentLoader(){
        return (
            <div className="col-lg-4 column-td-6">
                <div className="card-item blog-card">
                    <div className="card-image-wrap">
                        <div className="card-image">
                            <Shimmer source={base} />
                            <div className="card-meta card-loader mt-2">
                                <Shimmer />
                            </div>
                        </div>
                    </div>
                    <div className="card-content text-left">
                        <div className="card-title">
                            <Shimmer />
                        </div>
                        <p className="card-sub card-ell mt-3">
                            <Shimmer />
                            <Shimmer />
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="section-block"></div>
            <section className="blog-area padding-top-80px padding-bottom-60px">
                <div className="container">
                    <Fade top>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading text-center">
                                    <h2 className="sec__title">{language.news_title} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                    <p className="sec__desc">
                                    {language.news_text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="row mt-5">
                            
                            {
                            loading ?
                            <>
                                <ContentLoader />
                                <ContentLoader />
                                <ContentLoader />
                            </>:
                            <>
                            {news.map(item => (
                            <div key={item.id} className="col-lg-4 column-td-6">
                                <NavLink to={`/novidade/${item.url}`}>
                                    <div className="card-item blog-card">
                                        <div className="card-image-wrap">
                                            <div className="card-image">
                                                <img src={base}  style={{backgroundImage: `url(${item.image})`}} className="card__img cover-bg" />
                                                <div className="card-meta mt-2">
                                                    {item.time}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-content text-left">
                                            <div className="card-title">
                                                {item.title}
                                            </div>
                                            <p className="card-sub card-ell mt-3">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            ))}
                            </>
                            }
                        </div>
                    </Fade>
                </div>
            </section>
        </>
    );
}