import React from 'react';
import { Link } from 'react-router-dom';

import bg from '../../assets/images/pousada_5.jpg';
import logo from '../../assets/images/logo3.png';
import wave from '../../assets/images/waves.png';

export default function LinkMap() {
    
    return (
        <div className="linkmap-bg" style={{backgroundImage: `url(${bg})`}}>
            <style>{`
                    #widget-sb-reserve {
                        display: none !important;
                    }
                `}
            </style>
            <div className="linkmap-logo">
                <img src={logo} />
                <div className="linkmap-tel">(24) 3361-5769</div>
                <div className="linkmap-tel">(24) 3361-5266</div>
                <div className="linkmap-tel llast">(24) 98823-4500</div>
            </div>
            <div className="linkmap-buttons">
                <a className="link-white" target="_blank" href="https://sbreserva.silbeck.com.br/recreiodapraia">RESEVE AGORA</a>
                <a className="link-normal" target="_blank" href="https://wa.me/5524988234500">WHATSAPP</a>
                <Link className="link-white" to="/">NOSSO SITE</Link>
                <a className="link-normal" target="_blank" href="https://www.instagram.com/recreiodapraiapousada/">INSTAGRAM</a>
                <a className="link-white" target="_blank" href="https://www.facebook.com/recreiodapraia/">FACEBOOK</a>
                <a className="link-normal" target="_blank" href="https://open.spotify.com/playlist/5UATFVN815Q1NTyMt7zMVk?si=CAekWEuITymtVsCRpi0BTA">PLAYLIST SPOTIFY</a>
                <a className="link-white" target="_blank" href="https://webcheckin.silbeck.com.br/recreiodapraia/pt-br/">CHECK-IN ONLINE</a>
            </div>
            <div className="linkmap-wave">
                <img src={wave} />
            </div>
        </div>
    );
}