import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import base from '../../assets/images/blank-850.png';
import a_01 from '../../assets/images/a4_01.jpg';
import a_02 from '../../assets/images/a4_02.jpg';
import a_03 from '../../assets/images/a4_03.jpg';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';
import StandardJust from '../../components/StandardJust';
import Caracteristicas from '../../components/Caracteristicas';

import LanguageContext from '../../contexts/language';

export default function StandardQuadruplo() {

    const { language } = useContext(LanguageContext);

    return (
        <div>
            <Header />
            <HeaderNormal title={language.standard_3} subtitle={language.standard_3_text} />

            <section className="blog-single-area padding-top-40px padding-bottom-70px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-card rounded-lg">
                                <div className="card-image-wrap">
                                <Carousel
                                    interval={5000}
                                    transitionTime={500}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showArrows={false}
                                    showIndicators={true}
                                    showStatus={false}
                                    showThumbs={true}
                                    stopOnHover={false}
                                    >
                                        <div className="card-image">
                                            <img src={base}  style={{backgroundImage: `url(${a_01})`}} className="card__img cover-bg" />
                                        </div>
                                        <div className="card-image">
                                            <img src={base}  style={{backgroundImage: `url(${a_02})`}} className="card__img cover-bg" />
                                        </div>
                                        <div className="card-image">
                                            <img src={base}  style={{backgroundImage: `url(${a_03})`}} className="card__img cover-bg" />
                                        </div>
                                    </Carousel>
                                    
                                </div>
                                <div className="card-content pt-0 text-left">
                                    <h3 className="widget-title">{language.detail_standard}</h3>
                                    <p className="mb-3">
                                        {language.detail_standard_3_text_1}
                                    </p>
                                    <p className="mb-3">
                                        {language.detail_standard_3_text_2}
                                    </p>
                                    <p className="mb-3">
                                        {language.detail_standard_3_text_3}<br />
                                        {language.detail_standard_3_text_4}
                                    </p>
                                    <p className="mb-3">
                                        {language.detail_standard_3_text_5}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Caracteristicas />
                    </div>
                </div>
            </section>
            <StandardJust />
            <Footer newsletter={true} />
        </div>
    );
}