import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';

export default function Page404() {

    return (
        <div>
            <Header />
            <HeaderNormal title="Oops!" />
            <section className="about-area padding-top-100px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 mx-auto">
                        <div className="error-content text-center">
                            <div className="section-heading padding-top-30px">
                                <h3 className="sec__title pt-0 before-none">Página não encontrada!</h3>
                                <p className="sec__desc mb-4">
                                    Parece que o link que você tentou acessar não existe mais.
                                </p>
                            </div>
                                
                                <div className="btn-box">
                                    <NavLink to="/" className="theme-btn">
                                        Ir para Home
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer newsletter={true} />
        </div>
    );
}