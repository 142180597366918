import React, { useRef, useEffect, useState } from "react";
import { isMobile, isSafari } from "react-device-detect";

export default function HeaderVideo() {

    const refVideo = useRef(null);

    function playVideo(){
        refVideo.current.play();
        refVideo.current.muted = false;
    }

    useEffect(() => {
        
        setTimeout(function(){
            refVideo.current.play();
            if(!isSafari){
                refVideo.current.muted = false;
            }
        }, 2000);
        
    }, [refVideo]);

    return (
        <div className="video_container" onClick={playVideo}>
            <div className="video_home">
                <video ref={refVideo} autoPlay playsInline muted>
                    <source src="https://painel.recreiodapraia.com.br/assets/web/videos/video_home_2.mp4" type="video/mp4" />
                    <source src="https://painel.recreiodapraia.com.br/assets/web/videos/video_home_2.webm" type="video/webm" />
                    <source src="https://painel.recreiodapraia.com.br/assets/web/videos/video_home_2.ogv" type="video/ogg" />
                </video>
                <div>Praia do Abraãozinho</div>
            </div>
        </div>
    );
}