import React from 'react';
import banner from '../assets/images/ilha-grande/banner_7.jpg';
import { Parallax, Background } from 'react-parallax';

export default function Banner3() {

    return (
        <Parallax strength={500} className="banner-area">
            <Background className="banner-area-bg">
                <img src={banner} />
            </Background>
        </Parallax>
    );
}

