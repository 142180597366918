import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import HeaderNormal from '../../components/HeaderNormal';
import base from '../../assets/images/blank-600.png';
import LanguageContext from '../../contexts/language';

export default function NovidadesPost() {

    const { url } = useParams();

    const [post, setPost] = useState([]);
    const [post_items, setPostItems] = useState([]);
    const [another, setAnother] = useState([]);
    const [loading, setLoading] = useState(true);

    const lang = localStorage.getItem('language');
    const { language } = useContext(LanguageContext);

    async function loadPage(){
        const response = await api.get(`/post?url=${url}&lang=${lang}`);

        setPost(response.data.content.post[0]);
        setPostItems(response.data.content.post[0].post_items);
        setAnother(response.data.content.another);
        setLoading(false);
        
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [lang]);


    function LoaderAnother(){
        return (
            <div className="recent-item mb-3">
                <div className="recent-img">
                    <Shimmer source={base} />
                </div>
                <div className="recentpost-body">
                    <Shimmer />
                    <p className="recent__meta"><Shimmer width={120} /></p>
                </div>
            </div>
        )
    }
    return (
        <div>
            <Header />
            <HeaderNormal title={language.menu_novidades} subtitle={post.time} loading />

            <section className="blog-single-area padding-top-40px padding-bottom-70px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-card border-0">
                                <div className="card-content text-left pl-0 pr-0 pb-0">
                                    {
                                    loading ?
                                    <div>
                                        <Shimmer width={220} height={30} />
                                        <p className="mb-4">
                                            <Shimmer /><Shimmer /><Shimmer /><Shimmer />
                                        </p>
                                        <p className="mb-4">
                                            <Shimmer /><Shimmer /><Shimmer /><Shimmer />
                                        </p>
                                        <p className="mb-4">
                                            <Shimmer /><Shimmer /><Shimmer /><Shimmer />
                                        </p>
                                    </div>:
                                    <>
                                    <div className="card-title font-size-30 mb-3">
                                        {post.title}
                                    </div>
                                    {post_items.map((item, index) => (
                                    <div>
                                        {
                                        item.type=='image' &&
                                            <div key={`image-${index}`} className="image-fluid mb-3">
                                                <div className="row">
                                                    <div className="col-lg-12 column-td-6">
                                                        <img src={item.image} alt="blog-img" className="img-fluid radius-round" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                        item.type=='text' &&
                                            <div key={`text-${index}`} className="mb-3" dangerouslySetInnerHTML={{__html: item.text}} />
                                        }
                                        {
                                        item.type=='video' &&
                                            <div key={`video-${index}`} className="mb-3">
                                                <div className="video-container">
                                                    <iframe src={`https://www.youtube.com/embed/${item.video}?rel=0&showinfo=0`} frameborder="0" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    ))}
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar section-bg">
                                <div className="sidebar-widget similar-widget">
                                    <h3 className="widget-title">{language.news_another}</h3>
                                    <div className="title-shape"></div>
                                    <div className="similar-list padding-top-30px">
                                        {
                                        loading ?
                                        <div>
                                            <LoaderAnother />
                                            <LoaderAnother />
                                            <LoaderAnother />
                                        </div>:
                                        <>
                                        {another.map(item => (
                                            <div key={item.id} className="recent-item mb-3">
                                                <div className="recent-img">
                                                    <NavLink to={`/redirect/${item.url}`}>
                                                        <img src={base} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                                                    </NavLink>
                                                </div>
                                                <div className="recentpost-body">
                                                    <h4 className="recent__link">
                                                        <NavLink to={`/redirect/${item.url}`}>{item.title}</NavLink>
                                                    </h4>
                                                    <p className="recent__meta">{item.time}</p>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer newsletter={true} />
        </div>
    );
}