import React, { useState, useContext } from 'react';
import { FaRegUser, FaPhoneAlt, FaRegEnvelope, FaPencilAlt, FaFacebook, FaInstagram } from "react-icons/fa";
import InputMask from "react-input-mask";
import swal from 'sweetalert';
import Fade from 'react-reveal/Fade';

import api from '../services/api';
import waves from '../assets/images/waves_blue.png';
import LanguageContext from '../contexts/language';

export default function Contato() {

    const { language } = useContext(LanguageContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const phoneMask = v => {
        const digits = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digits || digits.length < 10) return v;
        const cut = digits.length === 10 ? 6 : 7;
        const max = digits.length > 11 ? 11 : digits.length;
        return `(${digits.substring(0, 2)}) ${digits.substring(2, cut)}-${digits.substring(cut, max)}`;
    }
    
    const maskBuilder = v => {
        if (!v || v.length == 0) return '';
        const a = phoneMask(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name=="" || email=="" || phone=="" || message==""){
                setLoading(false);
                swal("Opss!", "Os campos obrigatórios devem ser preenchidos!", "error");
                return;
            }
            await api.post('/contact', {
                name,
                email,
                phone,
                message,
            });

            setLoading(false);
            swal("Sucesso!", "Mensagem de contato enviada com sucesso!", "success");
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');

        }catch(err){
            setLoading(false);
            swal("Opss!", "Erro ao enviar mensagem!", "error");
        }
    }

    return (
        <div>
            {
            loading &&
            <div class="loader-container">
                <div class="loader-ripple"><div></div><div></div></div>
            </div>
            }
            <section className="add-listing-area padding-top-40px padding-bottom-100px">
                <div className="container">
                    <Fade top>
                        <div className="row">
                            <div className="col-lg-10 m-auto">
                                <div className="section-heading text-center">
                                    <h2 className="sec__title">{language.contact_title} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                    <p className="sec__desc">
                                    {language.contact_text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="row mt-5">
                            <div className="col-lg-8">
                                <form onSubmit={submitContact}>
                                    <div className="billing-form-item">
                                        <div className="billing-content">
                                            <div className="contact-form-action">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">{language.contact_name} <span className="color_red">*</span></label>
                                                            <div className="form-group">
                                                                <FaRegUser size={18} className="form-icon" />
                                                                <input className="form-control w-icon" type="text" value={name} onChange={e => setName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">{language.contact_email} <span className="color_red">*</span></label>
                                                            <div className="form-group">
                                                                <FaRegEnvelope size={18} className="form-icon" />
                                                                <input className="form-control w-icon" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">{language.contact_phone} <span className="color_red">*</span></label>
                                                            <div className="form-group">
                                                                <FaPhoneAlt size={18} className="form-icon" />
                                                                <InputMask className="form-control w-icon" value={phone} onChange={e => setPhone(phoneMask(e.target.value))} mask={maskBuilder(phone)} type="tel" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">{language.contact_message} <span className="color_red">*</span></label>
                                                            <div className="form-group mb-0">
                                                                <FaPencilAlt size={18} className="form-icon" />
                                                                <textarea rows="5" className="message-control form-control w-icon" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 border-0 mb-0 shadow-none">
                                        <div className="billing-content p-0">
                                            <div className="btn-box mt-4">
                                                <button type="submit" className="theme-btn border-0">{language.contact_send}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-lg-4">
                                <div className="billing-form-item">
                                    <div className="billing-content">
                                        <div className="our-office-content">
                                            <ul className="info-list">
                                                <li className="d-inline-block mb-4">
                                                {language.contact_address}:<br /><strong>Rua da Praia s/n - Vila do Abraão - Ilha Grande 3º distrito de Angra dos Reis - RJ, 23968-000</strong>
                                                </li>
                                                <li className="d-inline-block mb-4">
                                                {language.contact_phones}:<br /><strong>(24) 3361-5266<br />(24) 98823-4500</strong>
                                                </li>
                                                <li className="d-inline-block mb-4">
                                                {language.contact_email}:<br /><strong>reservas@recreiodapraia.com.br</strong>
                                                </li>
                                            </ul>
                                        </div>
                                        <p className="footer__desc text-uppercase">
                                            <strong>{language.contact_social}</strong>
                                        </p>
                                        <ul className="social-profile">
                                            <li>
                                                <a href="https://www.facebook.com/recreiodapraia/" target="_blank">
                                                    <FaFacebook size={18} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/recreiodapraiapousada/" target="_blank">
                                                    <FaInstagram size={18} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Fade>
                </div>
            </section>
           <div className="gmaps padding-bottom-60px">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.8224054848083!2d-44.17024298550245!3d-23.140165852056622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9c5bf56066e3a9%3A0x8582a3b8e97103ba!2sRecreio%20da%20Praia!5e0!3m2!1spt-BR!2sbr!4v1587766386837!5m2!1spt-BR!2sbr" width="100%" height="450" frameBorder="0" style={{border: 0, pointerEvents: 'none'}} allowFullScreen="" aria-hidden="false" tabIndex="0" className="lazyloaded"></iframe>
            </div>
        </div>
    );
}