import React, { useContext } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';
import News from '../../components/News';
import LanguageContext from '../../contexts/language';

export default function Novidades() {

    const { language } = useContext(LanguageContext);

    return (
        <>
            <Header />
            <HeaderNormal title={language.menu_novidades} />
            <News />
            <Footer newsletter={true} />
        </>
    );
}