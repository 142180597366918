import React, { useContext } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormalIlha from '../../components/HeaderNormalIlha';

import base from '../../assets/images/blank-850.png';

import ilha_1 from '../../assets/images/ilha_1.jpg';
import ilha_2 from '../../assets/images/ilha_2.jpg';
import ilha_3 from '../../assets/images/ilha_3.jpg';
import ilha_4 from '../../assets/images/ilha_4.jpg';
import ilha_5 from '../../assets/images/ilha_5.jpg';
import ilha_6 from '../../assets/images/ilha_6.jpg';
import ilha_7 from '../../assets/images/ilha_7.jpg';
import mapa from '../../assets/images/mapa.jpg';
import LanguageContext from '../../contexts/language';

export default function IlhaGrande() {

    const { language } = useContext(LanguageContext);

    function Content(props){
        return (
            <div className="container">
                <div className="row">
                    <div className="m-auto col-md-8">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="img-boxes">
                                    <div className="row">
                                        <div className="col-lg-12 column-td-6">
                                            <div className="img-box-item mt-4">
                                                <img src={base} style={{backgroundImage: `url(${props.image_1})`}} className="cover-bg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="about-content">
                                    <div className="section-heading text-center">
                                        <h2 className="sec__title mt-2">{props.title}</h2>
                                        <p className="sec__desc font-size-17 pr-3 mb-3 text-justify">
                                            {props.children}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <HeaderNormalIlha title="ILHA GRANDE" />
            <div className="padding-top-40px"></div>

            <section className="about-area padding-top-40px padding-bottom-40px">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-md-8">
                            <div className="row">
                                <div className="col-lg-10 m-auto">
                                    <div className="about-content">
                                        <div className="section-heading text-center">
                                            <h2 className="sec__title mt-2">{language.ilha_0}</h2>
                                            <p className="sec__desc font-size-17 pr-3 mb-3 text-center">
                                                {language.ilha_0_text_1}
                                            </p>
                                            <p className="sec__desc font-size-17 pr-3 mb-3 text-center">
                                                {language.ilha_0_text_2}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="img-boxes">
                                        <div className="row">
                                            <div className="col-lg-12 column-td-6">
                                                <div className="img-box-item mt-4">
                                                    <img src={mapa} className="cover-bg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area padding-top-40px padding-bottom-40px section-bg">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-content">
                            <div className="section-heading text-center">
                                <h2 className="sec__title mt-2">ILHA GRANDE</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <Content title={language.ilha_1} image_1={ilha_1} >
                    {language.ilha_1_text_1}<br />
                    {language.ilha_1_text_2}<br />
                    {language.ilha_1_text_3}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px">
                <Content title={language.ilha_2} image_1={ilha_2}>
                    {language.ilha_2_text_1}<br />
                    {language.ilha_2_text_2}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px section-bg">
                <Content title={language.ilha_3} image_1={ilha_3} >
                    {language.ilha_3_text_1}<br />
                    {language.ilha_3_text_2}<br />
                    {language.ilha_3_text_3}<br />
                    {language.ilha_3_text_4}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px">
                <Content title={language.ilha_4} image_1={ilha_4}>
                    {language.ilha_4_text_1}<br />
                    {language.ilha_4_text_2}<br />
                    {language.ilha_4_text_3}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px section-bg">
                <Content title={language.ilha_5} image_1={ilha_5}>
                    {language.ilha_5_text_1}<br />
                    {language.ilha_5_text_2}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px">
                <Content title={language.ilha_6} image_1={ilha_6}>
                    {language.ilha_6_text_1}<br />
                    {language.ilha_6_text_2}<br />
                    {language.ilha_6_text_3}<br />
                    {language.ilha_6_text_4}<br />
                    {language.ilha_6_text_5}
                </Content>
            </section>
            <section className="about-area padding-top-40px padding-bottom-40px section-bg">
                <Content title={language.ilha_7} image_1={ilha_7}>
                    {language.ilha_7_text_1}<br />
                    {language.ilha_7_text_2}<br />
                    {language.ilha_7_text_3}
                </Content>
            </section>
            <div className="padding-top-40px"></div>

            <Footer newsletter={true} />
        </div>
    );
}