import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import sobre from '../assets/images/sobre.jpg';
import waves from '../assets/images/waves_blue.png';
import LanguageContext from '../contexts/language';

export default function Sobre() {

    const { language } = useContext(LanguageContext);
    
    return (
        <section className="cta-area padding-top-100px padding-bottom-100px section-bg">
            <div className="container">
                <div className="row">
                    <Fade left cascade>
                        <div className="col-md-6">
                            <div className="img-boxes">
                                <img src={sobre} />
                            </div>
                        </div>
                    </Fade>
                    <Fade right cascade>
                        <div className="col-md-6 ml-auto">
                            <div className="mobile-app-content">
                                <div className="section-heading">
                                    <h5 className="sec__top mb-0">{language.sobre_title}</h5>
                                    <h2 className="sec__title mb-3">{language.sobre_subtitle} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                    <p className="sec__desc font-size-17 mb-4 text-justify">
                                        {language.sobre_text_1}
                                    </p>
                                    <p className="sec__desc font-size-17 mb-4 text-justify">
                                        {language.sobre_text_2}
                                    </p>
                                </div>
                                
                                <div className="btn-box text-left margin-top-20px">
                                    <NavLink to="/a-pousada" className="theme-btn">{language.saiba_mais}</NavLink>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
}