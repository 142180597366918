import React, { useState, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Pulse from 'react-reveal/Pulse';

import { FaFacebook, FaInstagram, FaRegEnvelope, FaWhatsapp } from "react-icons/fa";
import swal from 'sweetalert';
import api from '../services/api';

import logo from '../assets/images/logo2.png';
import waves from '../assets/images/waves.png';
import LanguageContext from '../contexts/language';
import Contact from './Contact';

export default function Footer(props) {
    
    const { language } = useContext(LanguageContext);

    const [email, setEmail] = useState('');
    const emailRef = useRef(null);
    const [loading, setLoading] = useState(false);

    async function submitNewsletter(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(email==""){
                setLoading(false);
                emailRef.current.focus();
                return;
            }
            await api.post('/newsletter', {email});

            setLoading(false);
            swal("Sucesso!", "Cadastro realizado com sucesso!", "success");
            setEmail('');

        }catch(err){
            setLoading(false);
            swal("Opss!", "Erro ao se cadastrar na Newsletter!", "error");
        }
    }

    return (
        <>  
            {
            loading &&
            <div class="loader-container">
                <div class="loader-ripple"><div></div><div></div></div>
            </div>
            }
            { props.newsletter &&
            <Pulse>
                <section className="cta-area cta-area2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cta-box d-flex align-items-center">
                                    <div className="col-md-7">
                                        <div className="section-heading">
                                            <h2 className="sec__title text-white">NEWSLETTER! <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                            <p className="sec__desc">{language.newsletter_text}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="contact-form-action">
                                            <form onSubmit={submitNewsletter}>
                                                <div className="form-group mb-0">
                                                    <FaRegEnvelope size={18} className="form-icon" />
                                                    <input ref={emailRef} value={email} onChange={e => setEmail(e.target.value)} className="form-control w-icon" type="email" placeholder={language.newsletter_email} />
                                                    <button className="theme-btn" type="submit">{language.newsletter_btn}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Pulse>
            }
            <div className="whatsapp">
                <a href="https://wa.me/5524988234500" target="_blank"><FaWhatsapp size={32} /></a>
            </div>
            <section id="contato" className="footer-area section-bg padding-top-100px padding-bottom-30px">
                <Contact />
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 column-td-6">
                            <div className="footer-item">
                                <div className="footer-logo">
                                    <img src={logo} alt="logo" />
                                    <p className="footer__desc">
                                    {language.footer_text}
                                    </p>
                                    <ul className="social-profile">
                                        <li>
                                            <a href="https://www.facebook.com/recreiodapraia/" target="_blank">
                                                <FaFacebook size={18} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/recreiodapraiapousada/" target="_blank">
                                                <FaInstagram size={18} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 column-td-6 text-center">
                            <div className="footer-item">
                                <h4 className="footer__title">Menu</h4>
                                <ul className="list-items">
                                    <li>
                                        <NavLink to="/a-pousada">{language.menu_pousada}</NavLink>
                                    </li>
                                    <li>
                                        <a href={language.link_checkin} target="_blank">{language.menu_checkin}</a>
                                    </li>
                                    <li>
                                        <a href="https://sbreserva.silbeck.com.br/recreiodapraia" target="_blank">{language.menu_reservas}</a>
                                    </li>
                                    <li>
                                        <NavLink to="/galeria">{language.menu_galeria}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/novidades">{language.menu_novidades}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 column-td-6 text-right">
                            <div className="footer-item">
                                <h4 className="footer__title">{language.menu_contato}</h4>
                                <ul className="list-items contact-links">
                                    <li>R. da Praia, s/n - Vila do Abraão,<br />Angra dos Reis - RJ, 23968-000</li>
                                    <li>(24) 3361-5769</li>
                                    <li>(24) 3361-5266</li>
                                    <li>(24) 98823-4500</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row copy-right margin-top-30px">
                        <div className="col-md-6">
                            <div className="margin-top-30px">
                                <p className="copy__desc">
                                    ©2020 Recreio da Praia. {language.copyright}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-right margin-top-30px text-right">
                                <p className="copy__desc float-right">
                                {language.developed} <a href="http://nerdetcetera.com/" target="_blank">Nerd Etcetera</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}