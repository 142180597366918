import React, { useState } from 'react';
import { IoMdSquareOutline, IoIosCheckbox, IoIosAdd, IoMdClose } from "react-icons/io";
import InputMask from "react-input-mask";
import cep from 'cep-promise';
import swal from 'sweetalert';

import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';
import waves from '../../assets/images/waves_blue.png';

export default function Contato() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profession, setProfession] = useState('');
    const [nationality, setNationality] = useState('');
    const [rg, setRG] = useState('');
    const [birthday, setBirthday] = useState('');
    const [genre, setGenre] = useState('');
    const [cpf, setCPF] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [date_start, setDateStart] = useState('');
    const [date_end, setDateEnd] = useState('');
    const [qto_adultos, setAdultos] = useState('');
    const [qto_criancas, setCriancas] = useState('');
    const [total_diarias, setTotalDiarias] = useState('');
    const [terms, setTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [non_edit, setNonEdit] = useState(false);
    const [motivo, setMotivo] = useState('');
    const [transporte, setTransporte] = useState('');
    const [acompanhantes, setAcompanhantes] = useState(1);
    const [name_1, setName_1] = useState('');
    const [cpf_1, setCPF_1] = useState('');
    const [birthday_1, setBirthday_1] = useState('');
    const [genre_1, setGenre_1] = useState('');
    const [name_2, setName_2] = useState('');
    const [cpf_2, setCPF_2] = useState('');
    const [birthday_2, setBirthday_2] = useState('');
    const [genre_2, setGenre_2] = useState('');
    const [name_3, setName_3] = useState('');
    const [cpf_3, setCPF_3] = useState('');
    const [birthday_3, setBirthday_3] = useState('');
    const [genre_3, setGenre_3] = useState('');
    const [regulamento, setRegulamento] = useState(false);
    
    const phoneMask = v => {
        const digits = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digits || digits.length < 10) return v;
        const cut = digits.length === 10 ? 6 : 7;
        const max = digits.length > 11 ? 11 : digits.length;
        return `(${digits.substring(0, 2)}) ${digits.substring(2, cut)}-${digits.substring(cut, max)}`;
    }
    
    const maskBuilder = v => {
        if (!v || v.length == 0) return '';
        const a = phoneMask(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }

    function searchZipcode(value) {
        setLoading(true);
        if(value!=''){
            cep(value)
            .then(function (response) {
                setAddress(response.street);
                setNeighborhood(response.neighborhood);
                setCity(response.city);
                setState(response.state);
                setLoading(false);
                setNonEdit(true);
            }).catch(function (error) {
                setAddress('');
                setNeighborhood('');
                setCity('');
                setState('');
                setLoading(false);
                setNonEdit(false);
                swal("Opss!", error.message, "error");
            });
        }
    }

    async function submitCheckIn(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name=="" || email=="" || phone==""){
                setLoading(false);
                swal("Opss!", "Os campos obrigatórios devem ser preenchidos!", "error");
                return;
            }else if(terms==false){
                setLoading(false);
                swal("Opss!", "Você precisa aceitar o regulamento!", "error");
                return;
            }

            const response = await api.post('/checkin', {
                name,
                email,
                phone,
                profession,
                nationality,
                rg,
                birthday,
                genre,
                cpf,
                zipcode,
                address,
                number,
                complement,
                neighborhood,
                city,
                state,
                date_start,
                date_end,
                qto_adultos,
                qto_criancas,
                total_diarias,
                terms,
                loading,
                non_edit,
                motivo,
                transporte,
                acompanhantes,
                name_1,
                cpf_1,
                birthday_1,
                genre_1,
                name_2,
                cpf_2,
                birthday_2,
                genre_2,
                name_3,
                cpf_3,
                birthday_3,
                genre_3,
                regulamento
            });
    
            setLoading(false);
            swal("Sucesso!", "Seus dados foram enviados com sucesso!", "success");
        
            //apagar variaveis e alertar o sucesso
            setName('');
            setEmail('');
            setPhone('');
            setProfession('');
            setNationality('');
            setRG('');
            setBirthday('');
            setGenre('');
            setCPF('');
            setZipcode('');
            setAddress('');
            setNumber('');
            setComplement('');
            setNeighborhood('');
            setCity('');
            setState('');
            setDateStart('');
            setDateEnd('');
            setAdultos('');
            setCriancas('');
            setTotalDiarias('');
            setTerms(false);
            setLoading(false);
            setNonEdit(false);
            setMotivo('');
            setTransporte('');
            setAcompanhantes(1);
            setName_1('');
            setCPF_1('');
            setBirthday_1('');
            setGenre_1('');
            setName_2('');
            setCPF_2('');
            setBirthday_2('');
            setGenre_2('');
            setName_3('');
            setCPF_3('');
            setBirthday_3('');
            setGenre_3('');
            setRegulamento(false);
            resetRadio()

        }catch(err){
            setLoading(false);
            swal("Opss!", "Erro ao enviar seu Check-In!", "error");
        }
    }

    function checkToogle(){
        terms ? setTerms(false) : setTerms(true);
    }
    function toogleRegulamento(){
        regulamento ? setRegulamento(false) : setRegulamento(true);
    }

    const handleMotivo = (e) =>{
        setMotivo(e.target.value);
    }

    const handleTransporte = (e) =>{
        setTransporte(e.target.value);
    }

    const addAcompanhante = () =>{
        if(acompanhantes==3){
            return;
        }
        setAcompanhantes((prevState) => prevState + 1);
    }
    const removeAcompanhante = () =>{
        if(acompanhantes==1){
            return;
        }
        setAcompanhantes((prevState) => prevState - 1);
    }

    function resetRadio(){
        document.getElementById("motivo_1").checked = false;
        document.getElementById("motivo_2").checked = false;
        document.getElementById("motivo_3").checked = false;
        document.getElementById("transporte_1").checked = false;
        document.getElementById("transporte_2").checked = false;
        document.getElementById("transporte_3").checked = false;
        document.getElementById("transporte_4").checked = false;
    
    }

    return (
        <div>
            {
            loading &&
            <div class="loader-container">
                <div class="loader-ripple"><div></div><div></div></div>
            </div>
            }
            <Header />
            <HeaderNormal title="CHECK-IN" />

            <section className="add-listing-area padding-top-40px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="section-heading text-center">
                                <h2 className="sec__title">FAÇA SEU CHECK-IN ONLINE <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                <p className="sec__desc">
                                    Nós estamos ansiosos para receber você!<br />Preencha um formulário para cada apartamento e agilize seu check-in.
                                </p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={submitCheckIn}>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <div className="billing-form-item">
                                    <div className="billing-content">
                                        <div className="contact-form-action">
                                            
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Nome Completo <span className="color_red">*</span></label>
                                                        <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">E-mail <span className="color_red">*</span></label>
                                                        <input className="form-control" value={email} onChange={e => setEmail(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Telefone para Contato <span className="color_red">*</span></label>
                                                        <InputMask className="form-control" value={phone} onChange={e => setPhone(phoneMask(e.target.value))} mask={maskBuilder(phone)} type="tel" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Profissão</label>
                                                        <input className="form-control" value={profession} onChange={e => setProfession(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Nacionalidade</label>
                                                        <input className="form-control" value={nationality} onChange={e => setNationality(e.target.value)} type="text" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Data de Nascimento</label>
                                                        <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={birthday} onChange={e => setBirthday(e.target.value)} type="tel" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Gênero</label>
                                                        <select className="form-control" value={genre} onChange={e => setGenre(e.target.value)}>
                                                                <option value="">----</option>
                                                                <option value="female">Feminino</option>
                                                                <option value="male">Masculino</option>
                                                            </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">RG</label>
                                                        <input className="form-control" value={rg} onChange={e => setRG(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">CPF</label>
                                                        <InputMask className="form-control" mask="999.999.999-99" value={cpf} onChange={e => setCPF(e.target.value)} type="tel" />
                                                    </div>
                                                </div>
                                               
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">CEP</label>
                                                        <InputMask className="form-control" mask="99999-999" value={zipcode} onBlur={e => searchZipcode(e.target.value)} onChange={e => setZipcode(e.target.value)} type="tel" />
                                                        <div>
                                                            <div className="zipcode-loader"></div>
                                                            <a className="link" target="_blank" href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm">Não sei o CEP</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Endereço</label>
                                                        <input className={`form-control ${non_edit && 'non-edit'}`} value={address} onChange={e => setAddress(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Número</label>
                                                        <input className="form-control" value={number} onChange={e => setNumber(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className="label-text">Complemento</label>
                                                        <input className="form-control" value={complement} onChange={e => setComplement(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className="label-text">Bairro</label>
                                                        <input className={`form-control ${non_edit && 'non-edit'}`} value={neighborhood} onChange={e => setNeighborhood(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className="label-text">Cidade</label>
                                                        <input className={`form-control ${non_edit && 'non-edit'}`} value={city} onChange={e => setCity(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className="label-text">Estado</label>
                                                        <input className={`form-control ${non_edit && 'non-edit'}`} value={state} onChange={e => setState(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Quantos Adultos?</label>
                                                        <input className="form-control" value={qto_adultos} onChange={e => setAdultos(e.target.value)} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Quantas Crianças</label>
                                                        <input className="form-control" value={qto_criancas} onChange={e => setCriancas(e.target.value)} type="text" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="label-text">Total de Diárias /Pacote</label>
                                                        <input className="form-control" value={total_diarias} onChange={e => setTotalDiarias(e.target.value)} type="text" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="label-text">Data de Entrada</label>
                                                        <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={date_start} onChange={e => setDateStart(e.target.value)} type="tel" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="label-text">Data de Saída</label>
                                                        <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={date_end} onChange={e => setDateEnd(e.target.value)} type="tel" />
                                                    </div>
                                                </div>

                                                
                                                
                                                <div className="col-md-12">
                                                    <label className="label-text">Motivo da Viagem</label>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="motivo_1" id="motivo_1" onChange={handleMotivo} name="motivo" /><label className="label-text" htmlFor="motivo_1">Lazer/Férias</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="motivo_2" id="motivo_2" onChange={handleMotivo} name="motivo" /><label className="label-text" htmlFor="motivo_2">Negócios</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="motivo_3" id="motivo_3" onChange={handleMotivo} name="motivo" /><label className="label-text" htmlFor="motivo_3">Congresso/Feira</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12"><hr /></div>
                                                <div className="col-md-12">
                                                    <label className="label-text">Meio de Transporte</label>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="transporte_1" id="transporte_1" onChange={handleTransporte} name="transporte" /><label className="label-text" htmlFor="transporte_1">Navio/Barco</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="transporte_2" id="transporte_2" onChange={handleTransporte} name="transporte" /><label className="label-text" htmlFor="transporte_2">Automóvel</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="transporte_3" id="transporte_3" onChange={handleTransporte} name="transporte" /><label className="label-text" htmlFor="transporte_3">Ônibus</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group radio">
                                                                <input type="radio" value="transporte_4" id="transporte_4" onChange={handleTransporte} name="transporte" /><label className="label-text" htmlFor="transporte_4">Avião</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <h5>Acompanhantes</h5>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className="label-text">Nome do Acompanhante</label>
                                                                <input className="form-control" value={name_1} onChange={e => setName_1(e.target.value)} type="text" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="label-text">CPF</label>
                                                                <InputMask className="form-control" mask="999.999.999-99" value={cpf_1} onChange={e => setCPF_1(e.target.value)} type="tel" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="label-text">Data de Nascimento</label>
                                                                <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={birthday_1} onChange={e => setBirthday_1(e.target.value)} type="tel" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="label-text">Gênero</label>
                                                                <select className="form-control" value={genre_1} onChange={e => setGenre_1(e.target.value)}>
                                                                        <option value="">----</option>
                                                                        <option value="female">Feminino</option>
                                                                        <option value="male">Masculino</option>
                                                                    </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    acompanhantes>=2 &&
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label className="label-text">Nome do Acompanhante</label>
                                                                    <input className="form-control" value={name_2} onChange={e => setName_2(e.target.value)} type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="label-text">CPF</label>
                                                                    <InputMask className="form-control" mask="999.999.999-99" value={cpf_2} onChange={e => setCPF_2(e.target.value)} type="tel" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="label-text">Data de Nascimento</label>
                                                                    <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={birthday_2} onChange={e => setBirthday_2(e.target.value)} type="tel" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="label-text">Gênero</label>
                                                                    <select className="form-control" value={genre_2} onChange={e => setGenre_2(e.target.value)}>
                                                                        <option value="">----</option>
                                                                        <option value="female">Feminino</option>
                                                                        <option value="male">Masculino</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    acompanhantes>=3 &&
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label className="label-text">Nome do Acompanhante</label>
                                                                    <input className="form-control" value={name_3} onChange={e => setName_3(e.target.value)} type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="label-text">CPF</label>
                                                                    <InputMask className="form-control" mask="999.999.999-99" value={cpf_3} onChange={e => setCPF_3(e.target.value)} type="tel" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="label-text">Data de Nascimento</label>
                                                                    <InputMask className="form-control" mask="99/99/9999" placeholder="dd/mm/yyyy" value={birthday_3} onChange={e => setBirthday_3(e.target.value)} type="tel" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="label-text">Gênero</label>
                                                                    <select className="form-control" value={genre_3} onChange={e => setGenre_3(e.target.value)}>
                                                                        <option value="">----</option>
                                                                        <option value="female">Feminino</option>
                                                                        <option value="mae">Masculino</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-12">
                                                    { acompanhantes<3 && <a className="link" style={{marginRight:20}} onClick={addAcompanhante}><IoIosAdd size={20} /> Adicionar Acompanhante</a> }
                                                    { acompanhantes>=2 && <a className="link" onClick={removeAcompanhante}><IoMdClose size={18} /> Remover Acompanhante</a> }
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                
                                <div className="billing-form-item p-0 border-0 mb-0 shadow-none">
                                    <div className="billing-content p-0">
                                                
                                        <div className="form-group">
                                            {
                                                terms ?
                                                <a onClick={checkToogle} className="btn-checkbox checked"><IoIosCheckbox size={20} /> EU ACEITO O </a>:
                                                <a onClick={checkToogle} className="btn-checkbox"><IoMdSquareOutline size={20} /> EU ACEITO O </a>
                                            }
                                            <a className="link" onClick={toogleRegulamento}>REGULAMENTO INTERNO</a>
                                        </div>

                                        <div className="btn-box mt-4">
                                            <button type="submit" className="theme-btn border-0">FAZER CHECK-IN</button>  <span className="color_red" style={{marginLeft:10}}>* Campos obrigatórios</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                {
                                regulamento &&
                                <div className="row">
                                    <div className="col-md-7 text-justify" style={{marginTop:20}}>

                                    <p><strong>INFORMA&Ccedil;&Otilde;ES DE HOSPEDAGEM:</strong></p>
                                    <p><br />
                                    1) O HOR&Aacute;RIO DO CHECK-IN &Eacute;&nbsp;A PARTIR DAS 13.00h DO DIA DE ENTRADA;<br />
                                    <br />
                                    2) O CHECK-OUT DO APARTAMENTO DEVER&Aacute; SER REALIZADO AT&Eacute; AS 11:00h DA MANH&Atilde;&nbsp;DO DIA DA SA&Iacute;DA, ENCERRAMENTOS AP&Oacute;S ESTE HOR&Aacute;RIO EST&Atilde;O SUJEITOS A COBRAN&Ccedil;AS ADICIONAIS;<br />
                                    <br />
                                    3) O RECIBO REFERENTE AO PAGAMENTO PELA OCUPA&Ccedil;&Atilde;O DO APARTAMENTO N&Atilde;O QUITAR&Aacute; DESPESAS EXTRAS, COMO REFEI&Ccedil;&Otilde;ES N&Atilde;O INCLU&Iacute;DAS, LAVANDERIA, TELEFONEMAS E OUTROS SERVI&Ccedil;OS OS QUAIS SER&Atilde;O COBRADOS &Agrave;&nbsp;VISTA, ASSIM COMO DESPESAS DECORRENTES DE DANOS CAUSADOS AO APARTAMENTO, ENXOVAL E &Aacute;REAS COMUNS;<br />
                                    <br />
                                    4) O APARTAMENTO DESTINA-SE EXCLUSIVAMENTE AO USO DE HOSPEDAGEM E &Eacute; ENTREGUE A SEU OCUPANTE ESTANDO EM PERFEITAS CONDI&Ccedil;&Otilde;ES DE CONSERVA&Ccedil;&Atilde;O E ASSEIO, CONTENDO EQUIPAMENTOS, MOBILI&Aacute;RIO E ENXOVAL,&nbsp;CASO N&Atilde;O SE MANIFESTE SOBRE ALGUMA EVENTUALIDADE LOGO A OCUPA&Ccedil;&Atilde;O DA UNIDADE, O MESMO TORNA-SE RESPONSAVEL POR DANOS QUE POSSAM OCORRER DURANTE A HOSPEDAGEM;<br />
                                    <br />
                                    5) PARA O ENCERRAMENTO DA CONTA &Eacute; NECESS&Aacute;RIO DESOCUPAR O APARTAMENTO, RETIRANDO BAGAGENS E ENTREGAR AS CHAVES NA RECEP&Ccedil;&Atilde;O;<br />
                                    <br />
                                    6) A ADMINISTRA&Ccedil;&Atilde;O DA POUSADA N&Atilde;O SE RESPONSABILIZA POR OBJETOS DE VALOR DEIXADOS NO APARTAMENTO, RECOMENDAMOS A UTILIZA&Ccedil;&Atilde;O DO COFRE DE SUA UNIDADE;<br />
                                    <br />
                                    7) A LEITURA DO REGULAMENTO INTERNO &Eacute;&nbsp;OBRIGAT&Oacute;RIA, O MESMO EST&Aacute; DISPONIVEL ATR&Aacute;S DA PORTA DO APARTAMENTO;<br />
                                    <br />
                                    8) NO CASO DE N&Atilde;O PAGAMENTO, AUTORIZO A POUSADA RETER MINHAS BAGAGENS AT&Eacute; QUE AS DESPESAS PENDENTES SEJAM QUITADAS;<br />
                                    <br />
                                    9) AFIRMO QUE AS INFORMA&Ccedil;&Otilde;ES PREENCHIDAS ACIMA S&Atilde;O VERDADEIRAS E QUE ESTOU DE ACORDO COM AS INFORMA&Ccedil;&Otilde;ES DE HOSPEDAGEM;</p>
                                    </div>
                                </div>
                                }
                            </div>

                        </div>

                    </form>
                </div>
            </section>
            
            <Footer newsletter={false} />
        </div>
    );
}