import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';
import Gallery from '../../components/Gallery';

export default function Galeria() {

    return (
        <div>
            <Header />
            <HeaderNormal title="Galeria" />
            <SimpleReactLightbox>
                <Gallery />
            </SimpleReactLightbox>
            <Footer newsletter={true} />
        </div>
    );
}