import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const languages = {
    pt: {
        language: "pt",
        language_pt: "Português",
        language_en: "Inglês",
        language_es: "Espanhol",
        language_sigla: "PT-BR",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        language_es_inverse: "Español",
        menu_home: "HOME",
        menu_sobre: "SOBRE NÓS",
        menu_pousada: "A POUSADA",
        menu_checkin: "CHECK-IN",
        menu_reservas: "RESERVAS",
        menu_galeria: "GALERIA",
        menu_novidades: "NOVIDADES",
        menu_contato: "CONTATO",
        welcome: "Bem-vindo a Pousada",
        recreio: "Recreio da Praia",
        title_comfort: "Conforto",
        standard_1: "Standard Duplo",
        standard_2: "Standard Triplo",
        standard_3: "Standard Quadruplo",
        standard_1_text: "Confortáveis, nossos quartos standard são equipados com 1 cama de casal ou 2 de solteiro.",
        standard_2_text: "Nossa suíte tripla é perfeita para vocês dividirem momentos de diversão.",
        standard_3_text: "Nosso quarto com 1 cama de casal e duas de solteiro ou 4 camas de solteiro é ideal.",
        sobre_title: "BEM-VINDO AO SEU NOVO REFÚGIO!",
        sobre_subtitle: "Sobre a Pousada",
        sobre_text_1: "A Pousada Recreio da Praia tem tudo para ser o seu cantinho preferido em Ilha Grande. Localizada de frente para o mar, com uma vista de tirar o fôlego, a pousada conta com acomodações confortáveis e uma área de lazer completa com piscina, sauna e salão de jogos. E o melhor: em um ambiente totalmente integrado com a natureza.",
        sobre_text_2: "Conecte-se à essência de Ilha Grande e desfrute de toda a tranquilidade e comodidade de nossas instalações.",
        saiba_mais: "Saiba Mais",
        ilha_grade_text_1: "Conheça este paraíso Brasileiro",
        mais_infos: "Mais informações",
        cliente_title: "DEPOIMENTOS",
        cliente_subtitle: "Clientes Satisfeitos",
        cliente_text_1: "Veja o que nossos queridos clientes andam comentando sobre nós!",
        cliente_1: "Apenas 100 metros do cais. Em um lugar de areia, faz toda a diferença, ao chegar com malas. Tem bastante opções de restaurantes próximos. As meninas da recepção são excelentes. Muito prestativa e simpáticas.",
        cliente_2: "Superou minhas expectativas . A localização é ótima , bem perto ao cais mas não em frente... Uma piscina bem boa com boa sauna também. Um café da manhã muito bem servido tendo ainda omelete e tapioca.",
        cliente_3: "Quarto amplo, piscina aquecida, localização ótima, café da manhã delicioso, funcionários atenciosos, e a pousada muito bem cuidada!",
        cliente_4: "Café da manhã muito bom, funcionários atenciosos com excelente localização. Recomendo!",
        cliente_5: "Localização excelente, quase em frente ao cais e na frente da praia. Cama ótima. Quarto amplo. Chuveiro bom. Meninas da recepção simpáticas e atenciosas.",
        news_title: "Últimas Novidades",
        news_text: "Saiba mais sobre as Novidades da Pousada",
        detail_standard: "DETALHES DA QUARTO",
        caracteristicas: "Características",
        caracteristica_1: "32'' TV LCD",
        caracteristica_2: "VENTILADOR DE TETO",
        caracteristica_3: "AR CONDICIONADO",
        caracteristica_4: "FRIGOBAR",
        caracteristica_5: "TELEFONE",
        caracteristica_6: "INTERNET (WIRELESS)",
        caracteristica_7: "SECADOR DE CABELO",
        caracteristica_8: "SERVIÇO DE LAVANDERIA (OPCIONAL)",
        caracteristica_9: "COFRE",
        detail_standard_1_text_1: "Confortáveis, nossos quartos standard são equipados com uma cama de casal ou duas de solteiro. Ideal para curtir um momento com o seu amor ou com um amigo ou amiga. Esse pedacinho de paraíso recarrega as energias, viu? Mar tranquilo, areia clara e todo o conforto da pousada Recreio da Praia te esperam em Ilha Grande.",
        detail_standard_1_text_2: "O quarto Standard possui uma cama de casal ou duas de solteiro.",
        detail_standard_1_text_3: "Nossos aptos comportam até 4 pessoas.",
        detail_standard_1_text_4: "Uma criança até 05 anos no mesmo apto de 02 adultos não paga.",
        detail_standard_1_text_5: "Todos os quartos são iguais, não havendo diferença de tarifas. Os quartos acomodam até 04 pessoas de acordo com os valores especificados e possuem uma bela vista para a piscina ou para o jardim. Nossos quartos estão localizados na parte interna da Pousada, não dispomos de unidades com vista para o mar.",
        detail_standard_2_text_1: "Nossa suíte tripla é perfeita para vocês dividirem momentos de diversão, com a infraestrutura do Recreio da Praia oferece.",
        detail_standard_2_text_2: "Vem curtir Ilha Grande com os amigos?",
        detail_standard_2_text_3: "Aqui na Recreio da Praia você desfruta da natureza de Ilha Grande e retorna para relaxar em uma pousada com a melhor infraestrutura da Vila do Abraão!",
        detail_standard_2_text_4: "O quarto possuí uma cama de casal e mais uma de solteiro ou 03 camas solteiro.",
        detail_standard_2_text_5: "Nossos aptos comportam até 4 pessoas.",
        detail_standard_2_text_6: "Uma criança até 05 anos no mesmo apto de 02 adultos não paga.",
        detail_standard_2_text_7: "Todos os quartos são iguais, não havendo diferença de tarifas. Os quartos acomodam até 04 pessoas de acordo com os valores especificados e possuem uma bela vista para a piscina ou para o jardim. Nossos quartos estão localizados na parte interna da Pousada, não dispomos de unidades com vista para o mar.",
        detail_standard_3_text_1: "Se a turma for maior ainda, nosso quarto com 1 cama de casal e duas de solteiro ou 4 camas de solteiro é ideal. É a pedida certa para você que vem com os amigos ou para casais com filhos, que querem curtir Ilha Grande em família. Somos um refúgio para você evaporar a metrópole dos poros com conforto e tranquilidade.",
        detail_standard_3_text_2: "O quarto quadruplo pode ser 01 cama de casal e 02 de solteiro ou 04 camas de solteiro.",
        detail_standard_3_text_3: "Nossos aptos comportam até 4 pessoas.",
        detail_standard_3_text_4: "Uma criança até 05 anos no mesmo apto de 02 adultos não paga.",
        detail_standard_3_text_5: "Todos os quartos são iguais, não havendo diferença de tarifas. Os quartos acomodam até 04 pessoas de acordo com os valores especificados e possuem uma bela vista para a piscina ou para o jardim. Nossos quartos estão localizados na parte interna da Pousada, não dispomos de unidades com vista para o mar.",
        page_sobre_title: "POUSADA RECREIO DA PRAIA",
        page_sobre_subtitle: "A Nossa História",
        page_sobre_text_1: "A Pousada Recreio da Praia fundada no ano de 1987, surgiu do sonho de um caiçara e uma carioca, começando como um pequeno camping.",
        page_sobre_text_2: "Naquela época, o número de turistas era bem pequeno e só existiam duas pequenas pousadas e dois pequenos restaurantes na Vila do Abraão. Atendendo aos pedidos dos hóspedes, decidimos criar um pequeno restaurante.",
        page_sobre_text_3: "Depois, com o crescimento no número de turistas, resolvemos montar a pousada. Começamos com 10 apartamentos e, após alguns anos, ampliamos o pequeno restaurante onde atualmente é servido o café da manhã.",
        page_sobre_text_4: "Anos mais tarde fizemos uma nova recepção, dessa vez mais aconchegante, e nossa área de lazer com piscina, sauna e salão de jogos. Com o passar do tempo construímos mais apartamentos e montamos também um belo jardim do jeito que amamos: com muito verde e frutas, sendo um atrativo para diversos pássaros nativos. Bem de acordo com a causa pela qual sempre lutamos: a preservação da Ilha Grande.",
        page_sobre_text_5: "Assim, o Recreio da Praia tomou forma com seus 35 apartamentos, instalações aconchegantes, e a nossa parte preferida: uma bela vista para o mar e para a floresta, paisagens sempre encantadoras.",
        btn_contact: "ENTRE EM CONTATO",
        sobre_pousada_1: "CONECTAR AS PESSOAS À ESSÊNCIA DE ILHA GRANDE",
        sobre_pousada_1_text: "Um lugar onde a natureza te abraça e você tem tudo para viver uma experiência inesquecível.",
        sobre_pousada_2: "A ARTE DE RECEBER",
        sobre_pousada_2_text: "A nossa arte é a de receber bem quem quer se aventurar ou descansar no paraíso, de um jeito autêntico com muito conforto e comodidade.",
        sobre_pousada_3: "ACREDITAMOS EM CONEXÕES HUMANAS COM A NATUREZA",
        sobre_pousada_3_text: "Vibramos em uma energia transcendental que é a necessidade básica de qualquer DNA: viver em simbiose com nossas raízes.",
        sobre_pousada_4: "ALMA SOLAR",
        sobre_pousada_4_text: "Somos a ponte do mundo material para quem quer resgatar a conexão com a natureza, combinando aventura com conforto e segurança. Temos a alma solar. Gostamos de lugares paradisíacos e mergulhamos de cabeça para recarregar energias. Luxo, pra nós, é colocar o pé na areia. Explorar trilhas, conhecer gente nova, esvaziar a cabeça e relaxar.",
        sobre_pousada_5: "AQUI, TUDO É POSSÍVEL",
        sobre_pousada_5_text: "Amamos acordar numa manhã de sol e reunir a família inteira em café da manhã à beira-mar. Lavamos a alma na água salgada, mas também valorizamos o conforto de voltar para um quarto aconchegante.",
        sobre_pousada_6: "O MELHOR JEITO DE APROVEITAR ILHA GRANDE",
        sobre_pousada_6_text: "A diversão não pode parar! Aqui você tem:",
        sobre_pousada_6_text_1: "Ambiente com infraestrutura completa integrado à natureza",
        sobre_pousada_6_text_2: "Comodidade para a família inteira",
        sobre_pousada_6_text_3: "Um lugar para você voltar sempre pra casa e ter água quentinha, cama gostosa com lençol cheiroso de qualidade e todos as comodidades e mimos possíveis.",
        sobre_more_1: "PAISAGEM DESLUMBRANTE",
        sobre_more_1_text: "Uma Ilha cheia de encantos, com 95% de sua área totalmente preservada e onde a mata se encontra com o mar verde-esmeralda, criando uma das visões mais belas que você terá em sua vida. Desfrute deste paraíso!",
        sobre_more_2: "UM POUCO MAIS SOBRE NÓS",
        sobre_more_2_text: "Temos o compromisso de ser um refúgio para evaporar a metrópole dos poros com conforto e tranquilidade.",
        bestof_title: "NOSSOS SERVIÇOS",
        bestof_subtitle: "O melhor para você",
        bestof_text_1: "Instalações confortáveis e com uma atmosfera aconchegante é o que você vai encontrar no Recreio da Praia.",
        bestof_text_2: "Nossas suítes proporcionam uma bela vista para o nosso jardim, repleto de verde e símbolo da nossa luta pela preservação de Ilha Grande.",
        bestof_1: "Lazer",
        bestof_1_text: "Além das mais 100 praias da Ilha Grande, a pousada Recreio da Praia oferece uma experiência diferenciada com sua estrutura de lazer que conta com piscina, sauna a vapor, salão de jogos (sinuca, pebolim), sala de TV, bangalôs de frente para o mar...",
        bestof_2: "Conforto e Limpeza",
        bestof_2_text: "Nossas instalações são confortáveis e possuem uma atmosfera aconchegante com vista para o nosso belo jardim com muito verde. Possuímos ainda, gerador e lavanderia industrial.",
        bestof_3: "Paisagem Incrível",
        bestof_3_text: "Como a preservação é sinônimo de orgulho para os visitantes da Ilha, a pousada dispõe de um ambiente totalmente integrado com a natureza.",
        bestof_4: "Acomodações",
        bestof_4_text: "Aqui você vai ter o silêncio da natureza e todo conforto de quartos aconchegantes equipados com TV, ar-condicionado, ventilador, cofre e secador de cabelo.",
        bestof_5: "Café da Manhã",
        bestof_5_text: "Oferecemos um delicioso café da manhã todos os dias para completar esta manhã saborosa. Tudo com gostinho caseiro, do jeitinho que você adora.",
        bestof_6: "Localização",
        bestof_6_text: "A pousada tem sua fachada voltada para o mar. Estrategicamente posicionada no centro do Abraão, ao lado da praça principal e à 125m da Estação marítima do Abraão, além de contar com vista para o Pico do Papagaio e do Ferreira.",
        contact_title: "ENTRE EM CONTATO",
        contact_text: "Nós estamos ansiosos para ouvir de você! Preencha suas informações de contato e um de nossos funcionários entrará em contato com você em breve.",
        contact_name: "Nome Completo",
        contact_email: "E-mail",
        contact_phone: "Telefone/Celular",
        contact_message: "Sua Mensagem",
        contact_send: "ENVIAR MENSAGEM",
        contact_send_f: "ENVIAR MENSAGEM",
        contact_address: "Endereço",
        contact_phones: "Telefones",
        contact_social: "SIGA-NOS EM NOSSAS REDES!",
        newsletter_text: "RECEBA NOVAS OFERTAS E PROMOÇÕES EXCLUSIVAS",
        newsletter_email: "Digite seu E-mail",
        newsletter_btn: "INSCREVA-SE",
        footer_text: "A MELHOR HOSPEDAGEM EM ILHA GRANDE",
        copyright: "Todos os direitos reservados.",
        developed: "Desenvolvido por",
        news_another: "Outras Novidades",

        ilha_0: "COMO CHEGAR",
        ilha_0_text_1: "No coração da Costa verde, a pousada  está localizada na Vila do Abraão, em Ilha Grande, Angra dos Reis – RJ.",
        ilha_0_text_2: "O acesso à Ilha Grande só pode ser realizado de barco partindo das cidades de: Angra dos Reis, Conceição de Jacareí ou Mangaratiba.",

        ilha_1: "Praia do Aventureiro",
        ilha_1_text_1: "A Praia de Aventureiro segundo muitas opiniões é o local mais paradisíaco da Ilha Grande com deslumbrantes paisagens. Resiste a décadas sem alterar suas características. Lugar para ouvir o som das ondas e curtir a luz de lampião e velas ou o luar. Aqui o tempo parece que quase parou o que notamos ao observar sua modesta vila de pescadores guarda muito da cultura local, hoje com mais de 100 moradores.",
        ilha_1_text_2: "A praia do Aventureiro apresenta cerca de 500m de areia fina e prateada, fundo raso e consistente, água límpida e mar agitado, exceto na extremidade esquerda de quem chega pelo mar, onde existe um pequeno cais para atracação. No lado esquerdo de quem olha do mar, está o coqueiro mais famoso da Ilha Grande, o “coqueiro deitado” é cartão postal da praia de Aventureiro e da Ilha Grande, no local também existe uma pequena piscina natural. Aventureiro é muito procurada por surfistas que chegam em grupos e hospedam-se em campings “caiçaras” autorizados pela Prefeitura de Angra dos Reis. Existe dois mirantes em Aventureiro, o da Espia, ao lado esquerdo de quem chega no cais, onde há uma escada de corda que conduz ao topo da predra mais alta da ponta do Aventureiro. O outro é o mirante da Sundara acessado por uma trilha em subida, que parte também próximo do cais, são cerca de 20minutos de subida mas o visual vale a pena, abrangendo grande parte da costa sudoeste da Ilha Grande. A praia vizinha, uma extensão de Aventureiro é chamada de praia do demo, esta sem habitações.",
        ilha_1_text_3: "Seu nome faz sentido porque nem sempre é fácil chegar à Praia do Aventureiro. Quando o mar está de ressaca não é aconselhável navegar, fazendo a travessia das “temíveis” pontas do Drago e Meros. Nessas circunstâncias o único jeito para se chegar em Aventureiro é caminhando a partir da Vila de Provetá.",
        ilha_2: "Praia Lopes Mendes",
        ilha_2_text_1: "Uma das praias mais bonitas de todo o litoral brasileiro está na Ilha Grande e chama-se Lopes Mendes, localizada na costa oceânica, de frente para o Sul, com aproximadamente 3 km de areia branca, muito fina e firme, é tida, já a muitos décadas, como a mais famosa de toda a Ilha Grande. O mar de Lopes Mendes parece sempre agitado, propicio para ao surf, por isso é a praia onde este esporte é o mais praticado.  É  uma praia de pouca profundidade e a transparência da água é impressionante, assim como sua variação de tonalidades.",
        ilha_2_text_2: "Lopes Mendes já foi muito habitada pela comunidade caiçara, quando a Colônia Penal de Dois Rios viveu o seu auge, mas hoje é uma praia quase deserta com muita sombra natural das amendoeiras que ficam em sua orla.",
        ilha_3: "Praia Dois Rios",
        ilha_3_text_1: "A praia de Dois Rios é fascinante, suas areias amarelas tem aproximadamente um quilômetro de extensão. O azul-esverdeado resplandecente das águas e as ondas que se formam com o vento do mar aberto, batem na praia nos convidando para um mergulho. É propícia para a prática de surf e pescaria pois sendo voltada para o mar aberto, a região é bem servida com pescados de qualidade.",
        ilha_3_text_2: "Reconhecido como um dos locais mais belos da Ilha Grande, esta deslumbrante localidade foi o pivô da fama de “Ilha Presídio”. Essa situação foi, talvez, a maior responsável pela preservação da Ilha Grande porque inibiu até 1993 a atividade turística. O Local abrigou o extinto presídio Cândido Mendes.",
        ilha_3_text_3: "Hoje a propriedade do Estado do Rio de Janeiro está sendo administrada pela Universidade Estadual do Rio de Janeiro – UERJ que montou um Centro de Estudos Ambientais e Desenvolvimento Sustentado – CEADS.",
        ilha_3_text_4: "A Vila de Dois Rios acolhe cerca de 150 moradores, dos quais uma boa parcela são funcionários da UERJ ocupando as antigas casas padronizadas, hoje em mau estado de conservação. É da Vila de Dois Rios que parte, quase diariamente, pescados que são vendidos na Vila do Abraão.",
        ilha_4: "Praia Parnaioca",
        ilha_4_text_1: "A bela Praia da Parnaioca é uma das praias mais isoladas de toda a Ilha Grande. De frente para o Atlântico com sua areia amarelada e grossa tem o mar agitado, é funda e com pouca sombra em seus 1.000 m de comprimento. Na extremidade oeste, deságua o Rio Parnaioca que é um encanto, formando uma pequena lagoa na praia. Na época áurea da Parnaioca, seu rio já foi bem mais volumoso, a ponto que era impossível atravessá-lo em época de chuvas fortes.",
        ilha_4_text_2: "Não existe iluminação por rede elétrica na Parnaioca. Nas áreas de acampamento a energia é provida por gerador elétrico. O sinal de celular é muito fraco ou inexistente. Um lugar para estar “desplugado” em total interação com a natureza, sem as distrações da modernidade.",
        ilha_4_text_3: "As ruínas de antigas construções feitas pelas mãos dos escravos e a pequena Igreja do Sagrado Coração de Jesus ainda hoje mantém seus traços originais. Este é um dos poucos vestígios da grande comunidade que aqui viveu. Quem a conhecer hoje jamais será capaz de imaginar que em meados do século 20 já foi a praia mais povoada da Ilha Grande, abrigando uma comunidade de mais de 1.000 pessoas que viviam da pesca e agricultura. Atualmente é uma praia quase deserta com apenas 5 habitantes.",
        ilha_5: "Praia Lagoa Azul",
        ilha_5_text_1: "A Lagoa Azul é uma piscina natural com fundo arenoso, algumas rochas e água predominantemente azul, localizada entre duas ilhotas na importante região histórica de Freguesia de Santana. O local é um agregado geográfico da Ilha dos Macacos, cuja extremidade norte, é o ponto da Ilha Grande que está mais próximo do continente. A região é belíssima com recantos dignos de chamarmos de paraíso. Está bem próxima de outras praias sensacionais, Grumixama, Baleia, Freguesia Sul e norte.",
        ilha_5_text_2: "Lagoa Azul, apelido turístico inspirado no clássico do cinema dos anos 80 com a atriz Brooke Shieldes, é um ponto turístico dos mais badalados da Ilha Grande. As embarcações ficam ancoradas e os visitantes podem descer para nadar, flutuar com “macarrões” (flutuadores de espuma) fornecidos pelas tripulações, praticar snorkel ou alimentar os peixinhos “sargentinhos” (nome científico abdufdef) com miolo de pão, já viciados de serem alimentados pelos visitantes. No alto verão, a região fica lotada de lanchas, veleiros, escunas.",
        ilha_6: "Praia Preta",
        ilha_6_text_1: "A praia preta com suas areias escuras (monazíticas) é um ponto de interesse geológico do PEIG – Parque Estadual da Ilha Grande. É a segunda e última praia do circuito Abraão – lado do Lazareto,  a esquerda de quem está na Vila do Abraão olhando para o mar.  A praia preta é muita frequentada todas as épocas do ano, oferece sombra sob árvores e nela deságua um rio que forma um pequeno lago de água doce e fria, onde pode-se banhar e nadar. As crianças adoram!",
        ilha_6_text_2: "A praia preta divide-se em duas partes, antes e depois das ruínas do antigo presidio. A primeira  ao lado da estrada que dá acesso a praia, onde está o laguinho de água doce e a parte final da praia, a mais reservada, com maior opção de sombra.",
        ilha_6_text_3: "A preta é uma boa opção para praticar snorkel, nadar, ler um livro, relaxar após o almoço, ouvindo o canto dos pássaros. Partindo daqui pode-se seguir a trilha que conduz ao aqueduto e poção, onde inicia a trila T2,  que segue para a enseada das Estrelas.",
        ilha_6_text_4: "As ruínas do antigo presídio e Lazareto.",
        ilha_6_text_5: "Na parte central da praia preta existem as ruínas de um enorme complexo de pavilhões que funcionaram de 1886-1913 como hospital para quarentena de imigrantes estrangeiros, local este que foi escolhido por DOM PEDRO II . Em 1939 foi usado pelo corpo de fuzileiros navais e de 1940 até 1954 funcionou como presídio para presos comuns do qual resta apenas um grande muro de pedras que se eleva da areia da praia, coberto de vegetação sobre um lage de concreto, sob a qual estão algumas celas de prisão.  Algumas outras paredes isoladas podem ser vistas dentro da mata próxima.  O Local atualmente está interditado, sob risco de desabamento.",
        ilha_7: "Praia Abraãozinho",
        ilha_7_text_1: "Esta é a sexta e última do circuito Abraão, está distante cerca de 1.200 metros da praia do Canto do Abraão, apresenta águas transparentes, mornas e calmas com sombra e a facilidade de um barzinho. Possui uma extensão chamada de praia do Sobrado, que pode ser acessada na bifurcação da trilha para praia do Abraãozinho já bem próximo a chegada.",
        ilha_7_text_2: "Abraãozinho é praia para se ir de manhã e voltar a tarde, propicia para o banho de mar, remar caiaque e praticar snorkel nas costeiras.",
        ilha_7_text_3: "Da praia do Abraão, partem diversos taxi-boat que conduzem os visitantes para a praia do Abraãozinho e de volta para a Vila.",
        link_checkin: "https://webcheckin.silbeck.com.br/recreiodapraia/pt-br/",
    },
    en: {
        language: "en",
        language_pt: "Portuguese",
        language_en: "English",
        language_es: "Spanish",
        language_sigla: "EN",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        language_es_inverse: "Español",
        menu_home: "HOME",
        menu_sobre: "ABOUT US",
        menu_pousada: "THE INN",
        menu_checkin: "CHECK-IN",
        menu_reservas: "BOOK NOW",
        menu_galeria: "GALLERY",
        menu_novidades: "NEWS",
        menu_contato: "CONTACT",
        welcome: "Welcome to the",
        recreio: "Recreio da Praia Inn",
        title_comfort: "Comfort",
        standard_1: "Standard 2 guests",
        standard_2: "Standard 3 guests",
        standard_3: "Standard 4 guests",
        standard_1_text: "Comfortable, our standard 2 guests rooms are equipped with a king size bed or two single beds.",
        standard_2_text: "Our 3 guests room is perfect for you to share moments of fun.",
        standard_3_text: "Our room with 1 king size bed and 2 single beds or 4 single beds is ideal.",
        sobre_title: "WELCOME TO YOUR NEW REFUGE!",
        sobre_subtitle: "ABOUT THE INN",
        sobre_text_1: "Recreio da Praia inn has everything to be your favorite spot in Ilha Grande. Located in front of the ocean, with a breathtaking view, the inn has comfortable accommodation and a leisure area complete with swimming pool, sauna and games room. And it’s best: an environment totally integrated with nature.",
        sobre_text_2: "Connect yourself to the essence of Ilha Grande and enjoy all the tranquility and convenience of our facilities.",
        saiba_mais: "Read More",
        ilha_grade_text_1: "Discover this Brazilian paradise",
        mais_infos: "More Information",
        cliente_title: "DEPOSITIONS",
        cliente_subtitle: "SATISFIED GUESTS",
        cliente_text_1: "Check it out what our dear customers are saying about us!",
        cliente_1: "Apenas 100 metros do cais. Em um lugar de areia, faz toda a diferença, ao chegar com malas. Tem bastante opções de restaurantes próximos. As meninas da recepção são excelentes. Muito prestativa e simpáticas.",
        cliente_2: "Superou minhas expectativas . A localização é ótima , bem perto ao cais mas não em frente... Uma piscina bem boa com boa sauna também. Um café da manhã muito bem servido tendo ainda omelete e tapioca.",
        cliente_3: "Quarto amplo, piscina aquecida, localização ótima, café da manhã delicioso, funcionários atenciosos, e a pousada muito bem cuidada!",
        cliente_4: "Café da manhã muito bom, funcionários atenciosos com excelente localização. Recomendo!",
        cliente_5: "Localização excelente, quase em frente ao cais e na frente da praia. Cama ótima. Quarto amplo. Chuveiro bom. Meninas da recepção simpáticas e atenciosas.",
        news_title: "NEWS",
        news_text: "Get to know more about our inn",
        detail_standard: "ROOM DETAILS",
        caracteristicas: "Characteristics",
        caracteristica_1: "32'' LCD TV",
        caracteristica_2: "Fan",
        caracteristica_3: "Air conditioning",
        caracteristica_4: "Mini-fridge",
        caracteristica_5: "Telephone",
        caracteristica_6: "wireless internet",
        caracteristica_7: "Hair dryer",
        caracteristica_8: "laundry service",
        caracteristica_9: "Safe-deposit box",
        detail_standard_1_text_1: "Comfortable, our standard 2 guests rooms are equipped with a king size bed or two single beds. Ideal to enjoy a moment with your love or with a friend. This little piece of paradise recharges your energy! Calm sea, white sand and all the comfort of the Pousada Recreio da Praia inn wait for you in Ilha Grande.",
        detail_standard_1_text_2: "The standard 2 guests room has a double bed or two single beds.",
        detail_standard_1_text_3: "Our apartments can accommodate up to 4 people.",
        detail_standard_1_text_4: "A child up to 05 years old at the same room as 02 adults does not pay.",
        detail_standard_1_text_5: "Our rooms are all similar, with no difference in rates. The rooms accommodate up to 04 people according to the specified values ​​and they have a beautiful view of the pool or the garden, we have no ocean view rooms.",
        detail_standard_2_text_1: "Our 3 guests room is perfect for you to share moments of fun, with all infrastructure that Recreio da Praia offers.",
        detail_standard_2_text_2: "Coming to enjoy Ilha Grande with friends?",
        detail_standard_2_text_3: "Here you enjoy the nature of Ilha Grande and return to relax at at Recreio da Praia with the best infrastructure in Vila do Abraão! ",
        detail_standard_2_text_4: "The room has a king size bed and another single bed or 03 single beds.",
        detail_standard_2_text_5: "Our apartments can accommodate up to 4 people.",
        detail_standard_2_text_6: "A child up to 05 years old at the same room as 02 adults does not pay.",
        detail_standard_2_text_7: "Our rooms are all similar, with no difference in rates. The rooms accommodate up to 04 people according to the specified values ​​and they have a beautiful view of the pool or the garden, we have no ocean view rooms.",
        detail_standard_3_text_1: "If the party is even bigger, our room with 1 king size bed and 2 single beds or 4 single beds is ideal. It is the right choice for you who come with friends or for couples with children, who want to enjoy Ilha Grande as a family. We are a refuge for you to evaporate the metropolis from the pores with comfort and tranquility. ",
        detail_standard_3_text_2: "The 4 guests room can be 01 king size bed and 02 single beds or 04 single beds.",
        detail_standard_3_text_3: "Our apartments can accommodate up to 4 people.",
        detail_standard_3_text_4: "A child up to 05 years old at the same room as 02 adults does not pay.",
        detail_standard_3_text_5: "Our rooms are all similar, with no difference in rates. The rooms accommodate up to 04 people according to the specified values ​​and they have a beautiful view of the pool or the garden, we have no ocean view rooms.",

        page_sobre_title: "RECREIO DA PRAIA INN",
        page_sobre_subtitle: "OUR HISTORY",
        page_sobre_text_1: "Recreio da Praia inn was founded in 1987, it came up from the dream of a “caiçara” (word in Portuguese for a person native from Ilha Grande) and a “carioca” (word in Portuguese for a person native from Rio de Janeiro), starting as a small camping site.",
        page_sobre_text_2: "At that time, the number of tourists was very small and there were only two small inns and two small restaurants in Vila do Abraão. So, in response to guests' requests, we decided to create a small restaurant. ",
        page_sobre_text_3: "Then, with the growth in the number of tourists, we decided to set up an inn. We started with 10 apartments and, after a few years, expanded the small restaurant to where breakfast is currently served.",
        page_sobre_text_4: "Years later pronouncing a new reception, this time cozier, and our leisure area with swimming pool, sauna and games room. Over time we built more apartments and set up a beautiful garden the way we love it: with lots of green and fruit, being an attraction for several native birds. According to the cause we have always fight for: the preservation of Ilha Grande. ",
        page_sobre_text_5: "Thus, Recreio da Praia took shape with its 35 apartments, cozy facilities, and our favorite part: a beautiful view of the ocean and the forest, always charming landscapes.",
        btn_contact: "CONTACT US",
        sobre_pousada_1: "CONNECT PEOPLE TO THE ESSENCE OF ILHA GRANDE",
        sobre_pousada_1_text: "A place where nature surrounds you and you have everything to live an unforgettable experience.",
        sobre_pousada_2: "THE ART OF RECEIVING",
        sobre_pousada_2_text: "Our art is to welcome those who are looking for adventure or just relaxing in paradise, in an authentic way with great comfort and convenience.",
        sobre_pousada_3: "WE BELIEVE IN HUMAN CONNECTIONS WITH NATURE",
        sobre_pousada_3_text: "We vibrate a transcendental energy that is the basic necessity of any DNA: to live in symbiosis with our roots. ",
        sobre_pousada_4: "SOLAR SOUL",
        sobre_pousada_4_text: "We are the bridge of the material world for those who want to rescue the connection with nature, combining adventure with comfort and safety. We have the solar soul. We like paradisiacal places to dive in and recharge. Luxury, for us, is putting your foot in the sand. Explore trails, meet new people, empty your head and relax.",
        sobre_pousada_5: "HERE, EVERYTHING IS POSSIBLE",
        sobre_pousada_5_text: "We love waking up on a sunny morning and gathering the whole family for breakfast by the ocean. We wash our souls in salt water, but we also value the comfort of returning to a cozy room. ",
        sobre_pousada_6: "THE BEST WAY TO ENJOY ILHA GRANDE ",
        sobre_pousada_6_text: "The fun can't stop! Here you can find:",
        sobre_pousada_6_text_1: "Facilities integrated with nature",
        sobre_pousada_6_text_2: "Comfort for the whole family",
        sobre_pousada_6_text_3: "A place for you always feel like home, with warm water, a nice bed with a high-quality sheet and all possible amenities and treats.",
        sobre_more_1: "STUNNING LANDSCAPE",
        sobre_more_1_text: "An island full of charm, with 95% of its area totally preserved and where the forest meets an emerald green ocean, creating one of the most beautiful views you will see in your life. Enjoy this paradise! ",
        sobre_more_2: "A LITTLE MORE ABOUT US",
        sobre_more_2_text: "We are committed to be your refuge to evaporate the metropolis from the pores with comfort and tranquility.",
        
        bestof_title: "OUR SERVICES",
        bestof_subtitle: "THE BEST FOR YOU",
        bestof_text_1: "Comfortable facilities and a cozy atmosphere are what you will find at Recreio da Praia.",
        bestof_text_2: "Our rooms provide a beautiful view of our garden, full of greenery and symbol of our flag, which is the preservation of Ilha Grande. ",
        bestof_1: "Recreation",
        bestof_1_text: "In addition to the more than 100 beaches on Ilha Grande, the Recreio da Praia inn offers a differentiated experience with its leisure structure that includes a swimming pool, steam sauna, games room (snooker, foosball), TV room, beachfront bungalows the sea",
        bestof_2: "Comfort and Cleanliness",
        bestof_2_text: "Our facilities are comfortable and have a cozy atmosphere overlooking our beautiful green garden. We also have industrial laundry and power generator incase of blackout and. ",
        bestof_3: "Amazing Landscape",
        bestof_3_text: "As preservation is synonymous of pride for visitors of the island, the inn has an environment fully integrated with nature.",
        bestof_4: "Rooms",
        bestof_4_text: "Here you will have the silence of nature and all comfort of cozy rooms equipped with TV, air conditioning, fan, safe and hairdryer.",
        bestof_5: "Breakfast",
        bestof_5_text: "We offer a delicious breakfast every day, so you begin your morning in a heavenly way, all with a homemade taste, the way you love. ",
        bestof_6: "Location",
        bestof_6_text: "The Inn is strategically positioned facing the ocean, downtown Abraão, next to the main square. It is 410ft from the Abraão maritime station, in addition it has the views of the peak of Papagaio and Ferreira.",
        contact_title: "CONTACT US",
        contact_text: "We are looking forward to hear from you! Fill in your contact information and we will contact you shortly. ",
        contact_name: "Full name",
        contact_email: "Email address",
        contact_phone: "Phone",
        contact_message: "Message",
        contact_send: "Send",
        contact_send_f: "Send Message",
        contact_address: "Address",
        contact_phones: "Phones",
        contact_social: "follow us on social media.",
        newsletter_text: "RECEIVE NEW OFFERS AND EXCLUSIVE PROMOTIONS.",
        newsletter_email: "Type your email address",
        newsletter_btn: "sign up",
        footer_text: "THE BEST ACCOMMODATION IN ILHA GRANDE",
        copyright: "All rights reserved.",
        developed: "Developed by",
        news_another: "Other News",

        ilha_0: "GETTING HERE",
        ilha_0_text_1: "The inn is located in the heart of the Green Coast, on Abraão village, in Big Island (Ilha Grande in Portuguese), Angra dos Reis - Rio de Janeiro.",
        ilha_0_text_2: "Access to Ilha Grande can only be done by boat from: Angra dos Reis, Conceição de Jacareí or Mangaratiba.",

        ilha_1: "AVENTUREIRO BEACH",
        ilha_1_text_1: "According to many opinions, Aventureiro beach is the most paradisiacal place on Ilha Grande with dazzling landscapes. Withstands decades without changing its characteristics. Place to listen to the sound of the waves and enjoy the light of the lantern and candles or the light of the moon. Here, time seems to have stopped almost what we notice when observing its modest fishing village that keeps much of the local culture, today with more than 100 inhabitants. ",
        ilha_1_text_2: "Aventureiro beach has about 500m of fine and silver sand, a shallow and consistent bottom, clear water and rough sea, except on the left end of those that reach the sea, where there is a small dock to moor. On the left side of those who look from the sea, there is the most famous coconut tree on Ilha Grande, the \"stretched coconut tree\" is a postcard of Aventureiro and Ilha Grande beach, there is also a small natural pool. Adventurers are very popular with surfers who come in groups and stay in \"caiçaras\" campsites authorized by the City of Angra dos Reis. There are two viewpoints in Aventureiro, Espia, on the left side of those who arrive at the pier, where there is a rope ladder that leads to the top of the highest peak at the Aventureiro point. The other is the Sundara viewpoint which is accessed by an uphill path, which also leaves near the pier, it is about 20 minutes uphill but the view is worth it, covering much of the southwest coast of Ilha Grande. The neighboring beach, an extension of Aventureiro called a demonstration beach, is homeless.",
        ilha_1_text_3: "Its name makes sense because it is not always easy to get to Praia do Aventureiro. When the sea is hungover, it is not advisable to navigate, crossing the “fearsome” points of Drago and Meros. In these circumstances, the only way to get to Aventureiro is by walking from the town of Provetá. ",
        ilha_2: "LOPES MENDES BEACH",
        ilha_2_text_1: "One of the most beautiful beaches on the entire Brazilian coast is located on Ilha Grande and is called Lopes Mendes, located on the ocean coast, facing south, with approximately 3 km of white, very fine and firm sand, there are already many decades, as the most famous of all the Ilha Grande. The Lopes Mendes sea always seems rough, suitable for surfing, making it the beach where this sport is most practiced. It is a shallow beach and the transparency of the water is impressive, as well as its variation in shades. ",
        ilha_2_text_2: "Lopes Mendes was already heavily inhabited by the Caiçara community, when the Dois Ríos Penal Colony experienced its peak, but today it is an almost deserted beach with a lot of natural shade from the almond trees on its edge. ",
        ilha_3: "DOS RÍOS BEACH",
        ilha_3_text_1: "Dois Rios beach is fascinating, its yellow sands are approximately one kilometer long. The blue-green glow of the waters and the waves that form with the wind from the open sea hit the beach inviting us to swim. It is suitable for surfing and fishing as it faces the open sea, the region is well served with quality fish. ",
        ilha_3_text_2: "Recognized as one of the most beautiful places in Ilha Grande, this impressive place was the axis of the “Ilha Presídio” fame. This situation was, perhaps, the main responsible for the preservation of Ilha Grande because it inhibited tourist activity until 1993. The site housed the extinct Cândido Mendes prison.",
        ilha_3_text_3: "Today the property of the State of Rio de Janeiro is being administered by the State University of Rio de Janeiro - UERJ, which created a Center for Environmental Studies and Sustainable Development - CEADS.",
        ilha_3_text_4: "The town of Dois Ríos welcomes some 150 residents, a good part of which are UERJ employees who occupy the old standard houses, now in poor condition. It is from the town of Dois Rios where fish is sold almost daily, which is sold in Vila do Abraão. ",
        ilha_4: "PARNAIOCA BEACH",
        ilha_4_text_1: "The beautiful Praia da Parnaioca is one of the most isolated beaches in all of Ilha Grande. Facing the Atlantic with its thick yellowish sand, the sea is rough, deep and with little shade in its 1,000 m length. At the west end the Parnaioca River flows, which is a charm, forming a small lagoon on the beach. At the height of Parnaioca, its river was already much more voluminous, to the point that it was impossible to cross it in times of heavy rain. ",
        ilha_4_text_2: "There is no electricity supply in Parnaioca. In the camping areas the energy is provided by an electric generator. The cellular signal is too weak or nonexistent. A place to \"disconnect\" in total interaction with nature, without the distractions of modernity. ",
        ilha_4_text_3: "The ruins of ancient constructions made by the hands of slaves and the small Church of the Sacred Heart of Jesus still maintain their original characteristics. This is one of the few vestiges of the great community that lived here. Those who know it today can never imagine that in the middle of the 20th century it was once the most populated beach on Ilha Grande, housing a community of more than 1,000 people who made their living from fishing and agriculture. Currently it is an almost deserted beach with only 5 inhabitants.",
        ilha_5: "LAGUNA AZUL BEACH",
        ilha_5_text_1: "Lagoa Azul is a natural pool with a sandy bottom, some rocks and predominantly blue water, located between two islets in the important historical region of Freguesia de Santana. The site is a geographic aggregate of Ilha dos Macacos, whose northern end is the point of Ilha Grande closest to the mainland. The region is beautiful with corners worthy of calling paradise. It is very close to other sensational beaches, Grumixama, Baleia, Parroquia Sur and Norte. ",
        ilha_5_text_2: "Lagoa Azul, a tourist nickname inspired by the classic 80s cinema with actress Brooke Shieldes, is one of the most popular tourist spots on Ilha Grande. The boats are anchored and visitors can go down for a swim, float with \"noodles\" (foam floats) provided by the crews, snorkel or feed the fish \"sargentinhos\" (scientific name abdufdef) with breadcrumbs, already addicted to being fed. by visitors. In the height of summer, the region fills with speedboats, sailboats, schooners. ",
        ilha_6: "BLACK BEACH",
        ilha_6_text_1: "The black beach with its dark sands (monazitic) is a geological point of interest in PEIG - Ilha Grande State Park. It is the second and last beach of the Abraão circuit - Lazareto side, to the left of those in Vila do Abraão facing the sea. The black beach is very popular at all times of the year, it offers shade under the trees and a river flows into it that forms a small lake of fresh and cold water, where you can bathe and swim. Kids love it!",
        ilha_6_text_2: "The black beach is divided into two parts, before and after the ruins of the old prison. The first is next to the road that gives access to the beach, where there is a freshwater pond and the final part of the beach, the most reserved, with the greatest option of shade. ",
        ilha_6_text_3: "Black is a good option for snorkeling, swimming, reading a book, relaxing after lunch, listening to the singing of birds. From here you can follow the trail that leads to the aqueduct and potion, where trail T2 begins, leading to Star Cove. ",
        ilha_6_text_4: "The ruins of the old prison and Lazaretto.",
        ilha_6_text_5: "In the central part of the black beach are the ruins of a huge complex of pavilions that functioned from 1886-1913 as a hospital for the quarantine of foreign immigrants, a location chosen by DOM PEDRO II. In 1939 it was used by the Marine Corps and from 1940 to 1954 it served as a prison for common prisoners, of which there is only a large stone wall that rises above the sand of the beach, covered with vegetation on a slab of concrete, under which are some prison cells. Some other isolated walls can be seen within the nearby forests. The site is currently closed, at risk of collapse.",
        ilha_7: "ABRAÃOZINHO BEACH",
        ilha_7_text_1: "This is the sixth and last circuit of Abraão, it is located about 1,200 meters from the Canto do Abraão beach, it has transparent, warm and calm waters with shade and the ease of a small bar. It has an extension called Praia do Sobrado, which can be accessed at the bifurcation of the path to Abraãozinho beach, very close to the arrival. ",
        ilha_7_text_2: "Abraãozinho is a beach to go in the morning and return in the afternoon, it allows you to bathe in the sea, row in kayaks and snorkel off the coast. ",
        ilha_7_text_3: "Several taxi boats depart from Abraão beach that take visitors to Abraãozinho beach and back to town.",

        link_checkin: "https://webcheckin.silbeck.com.br/recreiodapraia/en-us",
    },
    es: {
        language: "es",
        language_pt: "Portugués",
        language_en: "Inglés",
        language_es: "Español",
        language_sigla: "ES",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        language_es_inverse: "Español",
        menu_home: "INICIO",
        menu_sobre: "SOBRE NOSOTROS",
        menu_pousada: "LA POSADA",
        menu_checkin: "CHECK-IN",
        menu_reservas: "RESERVA AHORA",
        menu_galeria: "GALERIA",
        menu_novidades: "NOTICIAS",
        menu_contato: "CONTACTO",
        welcome: "Bienvenido",
        recreio: "Posada Recreio da Praia",
        title_comfort: "Comodidad",
        standard_1: "Estándar 2 huéspedes",
        standard_2: "Estándar 3 personas",
        standard_3: "Estándar 4 personas",
        standard_1_text: "Cómodas, nuestras habitaciones estándar para 2 huéspedes están equipadas con una cama king size o dos camas individuales.",
        standard_2_text: "Nuestra habitación de 3 huéspedes es perfecta para compartir momentos de diversión.",
        standard_3_text: "Nuestra habitación con 1 cama king size y 2 camas individuales o 4 camas individuales es ideal.",
        sobre_title: "¡BIENVENIDO A SU NUEVO REFUGIO!",
        sobre_subtitle: "ACERCA DE LA POSADA",
        sobre_text_1: "La posada Recreio da Praia tiene todo para ser su lugar favorito en Ilha Grande. Ubicada frente al mar, con una vista impresionante, la posada cuenta con cómodas habitaciones y un área de esparcimiento completa con alberca, sauna y sala de juegos. Y lo mejor: un entorno totalmente integrado con la naturaleza.",
        sobre_text_2: "Conéctese con la esencia de Ilha Grande y disfrute de toda la tranquilidad y comodidad de nuestras instalaciones.",
        saiba_mais: "Lea mas",
        ilha_grade_text_1: "Descubra este paraíso brasileño",
        mais_infos: "Más información",
        cliente_title: "DEPOSICIONES",
        cliente_subtitle: "INVITADOS SATISFECHOS",
        cliente_text_1: "¡Vea lo que nuestros queridos clientes dicen sobre nosotros!",
        cliente_1: "Apenas 100 metros do cais. Em um lugar de areia, faz toda a diferença, ao chegar com malas. Tem bastante opções de restaurantes próximos. As meninas da recepção são excelentes. Muito prestativa e simpáticas.",
        cliente_2: "Superou minhas expectativas . A localização é ótima , bem perto ao cais mas não em frente... Uma piscina bem boa com boa sauna também. Um café da manhã muito bem servido tendo ainda omelete e tapioca.",
        cliente_3: "Quarto amplo, piscina aquecida, localização ótima, café da manhã delicioso, funcionários atenciosos, e a pousada muito bem cuidada!",
        cliente_4: "Café da manhã muito bom, funcionários atenciosos com excelente localização. Recomendo!",
        cliente_5: "Localização excelente, quase em frente ao cais e na frente da praia. Cama ótima. Quarto amplo. Chuveiro bom. Meninas da recepção simpáticas e atenciosas.",
        news_title: "Noticias",
        news_text: "Conozca más sobre nuestra posada",
        detail_standard: "DETALLES DE LA HABITACIÓN",
        caracteristicas: "Caracteristicas",
        caracteristica_1: "32'' LCD TV",
        caracteristica_2: "Ventilador",
        caracteristica_3: "Aire acondicionado",
        caracteristica_4: "Frigobar",
        caracteristica_5: "Teléfono",
        caracteristica_6: "Wifi",
        caracteristica_7: "Secador de pelo",
        caracteristica_8: "servicio de lavandería",
        caracteristica_9: "Caja de seguridad",
        detail_standard_1_text_1: "Cómodas, nuestras habitaciones estándar para 2 huéspedes están equipadas con una cama king size o dos camas individuales. Ideal para disfrutar de un momento con tu amor o con un amigo. ¡Este pequeño paraíso recarga tu energía! Mar en calma, arena blanca y todas las comodidades de la posada Pousada Recreio da Praia le esperan en Ilha Grande.",
        detail_standard_1_text_2: "La habitación estándar para 2 huéspedes tiene una cama doble o dos camas individuales.",
        detail_standard_1_text_3: "Nuestros apartamentos pueden alojar hasta 4 personas.",
        detail_standard_1_text_4: "Un niño hasta 05 años en la misma habitación que 02 adultos no paga.",
        detail_standard_1_text_5: "Nuestras habitaciones son todas similares, sin diferencia de tarifas. Las habitaciones acomodan hasta 04 personas según los valores especificados y tienen una hermosa vista a la alberca o al jardín, no tenemos habitaciones con vista al mar.",
        detail_standard_2_text_1: "Nuestra habitación de 3 huéspedes es perfecta para compartir momentos de diversión, con toda la infraestructura que ofrece Recreio da Praia.",
        detail_standard_2_text_2: "¿Vienes a disfrutar de Ilha Grande con amigos?",
        detail_standard_2_text_3: "¡Aquí disfruta de la naturaleza de Ilha Grande y regresa para relajarse en Recreio da Praia con la mejor infraestructura en Vila do Abraão! ",
        detail_standard_2_text_4: "La habitación tiene una cama king size y otra cama individual o 03 camas individuales.",
        detail_standard_2_text_5: "Nuestros apartamentos pueden alojar hasta 4 personas.",
        detail_standard_2_text_6: "Un niño hasta 05 años en la misma habitación que 02 adultos no paga.",
        detail_standard_2_text_7: "Nuestras habitaciones son todas similares, sin diferencia de tarifas. Las habitaciones acomodan hasta 04 personas según los valores especificados y tienen una hermosa vista a la alberca o al jardín, no tenemos habitaciones con vista al mar.",
        detail_standard_3_text_1: "Si la fiesta es aún más grande, nuestra habitación con 1 cama king size y 2 camas individuales o 4 camas individuales es ideal. Es la elección adecuada para ti que vienes con amigos o para parejas con niños que quieran disfrutar de Ilha Grande en familia. Somos un refugio para que evapores la metrópoli de los poros con comodidad y tranquilidad. ",
        detail_standard_3_text_2: "La habitación para 4 huéspedes puede tener 01 cama king size y 02 camas individuales o 04 camas individuales. ",
        detail_standard_3_text_3: "Nuestros apartamentos pueden alojar hasta 4 personas.",
        detail_standard_3_text_4: "Un niño hasta 05 años en la misma habitación que 02 adultos no paga.",
        detail_standard_3_text_5: "Nuestras habitaciones son todas similares, sin diferencia de tarifas. Las habitaciones acomodan hasta 04 personas según los valores especificados y tienen una hermosa vista a la alberca o al jardín, no tenemos habitaciones con vista al mar. ",

        page_sobre_title: "POSADA RECREIO DA PRAIA",
        page_sobre_subtitle: "NUESTRA HISTORIA",
        page_sobre_text_1: "La posada Recreio da Praia fue fundada en 1987, surgió del sueño de una \"caiçara\" (palabra en portugués para una persona nativa de Ilha Grande) y una \"carioca\" (palabra en portugués para una persona nativa de Río de Janeiro) , comenzando como un pequeño camping.",
        page_sobre_text_2: "En ese momento, el número de turistas era muy pequeño y solo había dos pequeñas posadas y dos pequeños restaurantes en Vila do Abraão. Entonces, en respuesta a las solicitudes de los huéspedes, decidimos crear un pequeño restaurante. ",
        page_sobre_text_3: "Luego, con el crecimiento del número de turistas, decidimos montar una posada. Empezamos con 10 apartamentos y, después de unos años, ampliamos el pequeño restaurante hasta donde actualmente se sirve el desayuno. ",
        page_sobre_text_4: "Años más tarde pronunciando una nueva recepción, esta vez más acogedora, y nuestra zona de ocio con piscina, sauna y sala de juegos. Con el tiempo construimos más departamentos y montamos un hermoso jardín como nos encanta: con mucho verde y fruta, siendo un atractivo para varias aves nativas. Según la causa por la que siempre hemos luchado: la preservación de Ilha Grande. ",
        page_sobre_text_5: "Así, Recreio da Praia tomó forma con sus 35 apartamentos, acogedoras instalaciones y nuestra parte favorita: una hermosa vista al mar y al bosque, paisajes siempre encantadores.",
        btn_contact: "CONTÁCTENOS",
        sobre_pousada_1: "CONECTAR A LAS PERSONAS CON LA ESENCIA DE ILHA GRANDE",
        sobre_pousada_1_text: "Un lugar donde la naturaleza te rodea y lo tienes todo para vivir una experiencia inolvidable.",
        sobre_pousada_2: "EL ARTE DE RECIBIR",
        sobre_pousada_2_text: "Nuestro arte es dar la bienvenida a quienes buscan aventura o simplemente relajarse en el paraíso, de una manera auténtica con gran comodidad y conveniencia. ",
        sobre_pousada_3: "CREEMOS EN LAS CONEXIONES HUMANAS CON LA NATURALEZA",
        sobre_pousada_3_text: "Vibramos una energía trascendental que es la necesidad básica de cualquier ADN: vivir en simbiosis con nuestras raíces. ",
        sobre_pousada_4: "ALMA SOLAR",
        sobre_pousada_4_text: "Somos el puente del mundo material para quienes quieren rescatar la conexión con la naturaleza, combinando aventura con comodidad y seguridad. Tenemos el alma solar. Nos gustan los lugares paradisíacos para bucear y recargar energías. El lujo, para nosotros, es poner el pie en la arena. Explore senderos, conozca gente nueva, vacíe su cabeza y relájese.",
        sobre_pousada_5: "AQUÍ TODO ES POSIBLE",
        sobre_pousada_5_text: "Nos encanta despertarnos en una mañana soleada y reunir a toda la familia para desayunar junto al mar. Lavamos nuestras almas en agua salada, pero también valoramos la comodidad de regresar a una habitación acogedora. ",
        sobre_pousada_6: "LA MEJOR MANERA DE DISFRUTAR ILHA GRANDE",
        sobre_pousada_6_text: "¡La diversión no puede parar! Aquí puedes encontrar:",
        sobre_pousada_6_text_1: "Instalaciones integradas con la naturaleza",
        sobre_pousada_6_text_2: "Confort para toda la familia",
        sobre_pousada_6_text_3: "Un lugar para que siempre se sienta como en casa, con agua tibia, una bonita cama con sábanas de alta calidad y todas las comodidades y delicias posibles.",
        sobre_more_1: "PAISAJE IMPRESIONANTE",
        sobre_more_1_text: "Una isla llena de encanto, con el 95% de su área totalmente preservada y donde el bosque se encuentra con un océano verde esmeralda, creando una de las vistas más hermosas que verás en tu vida. ¡Disfruta de este paraíso! ",
        sobre_more_2: "UN POCO MÁS SOBRE NOSOTROS",
        sobre_more_2_text: "Nos comprometemos a ser tu refugio para evaporar la metrópoli de los poros con comodidad y tranquilidad.",

        bestof_title: "NUESTROS SERVICIOS",
        bestof_subtitle: "LO MEJOR PARA TI",
        bestof_text_1: "Instalaciones cómodas y un ambiente acogedor son lo que encontrará en Recreio da Praia.",
        bestof_text_2: "Nuestras habitaciones brindan una hermosa vista de nuestro jardín, lleno de vegetación y símbolo de nuestra bandera, que es la preservación de Ilha Grande. ",
        bestof_1: "Recreación",
        bestof_1_text: "Además de las más de 100 playas en Ilha Grande, la posada Recreio da Praia ofrece una experiencia diferenciada con su estructura de ocio que incluye piscina, sauna de vapor, sala de juegos (billar, metegol), sala de TV, bungalows frente al mar. ",
        bestof_2: "Comodidad y limpieza",
        bestof_2_text: "Nuestras instalaciones son cómodas y tienen un ambiente acogedor con vista a nuestro hermoso jardín verde. También contamos con, generador y lavandería industrial.",
        bestof_3: "Paisaje asombroso",
        bestof_3_text: "Como la preservación es sinónimo de orgullo para los visitantes de la isla, la posada cuenta con un entorno totalmente integrado con la naturaleza.",
        bestof_4: "Habitaciones",
        bestof_4_text: "Aquí tendrá el silencio de la naturaleza y todas las comodidades de acogedoras habitaciones equipadas con TV, aire acondicionado, ventilador, caja fuerte y secador de pelo.",
        bestof_5: "Desayuno",
        bestof_5_text: "Ofrecemos un delicioso desayuno todos los días, para que comiences tu mañana de una manera celestial, todo con un sabor casero, como amas. ",
        bestof_6: "Ubicación",
        bestof_6_text: "La posada tiene una fachada frente al mar estratégicamente ubicada en el centro de Abraão, junto a la plaza principal está a 125m de la estación marítima de Abraão, además de tener vistas al pico de Papagaio y Ferreira. ",
        contact_title: "CONTÁCTENOS",
        contact_text: "¡Esperamos tener noticias tuyas! Complete su información de contacto y nos comunicaremos con usted a la brevedad.",
        contact_name: "Nombre completo",
        contact_email: "Dirección de correo electrónico",
        contact_phone: "Teléfono",
        contact_message: "Mensaje",
        contact_send: "Envíanos",
        contact_send_f: "Enviar mensaje",
        contact_address: "Habla a",
        contact_phones: "Teléfonos",
        contact_social: "síguenos en las redes sociales.",
        newsletter_text: "RECIBE NUEVAS OFERTAS Y PROMOCIONES EXCLUSIVAS.",
        newsletter_email: "Dirección de correo electrónico",
        newsletter_btn: "SUSCRIBIRSE",
        footer_text: "EL MEJOR ALOJAMIENTO EN ILHA GRANDE",
        copyright: "Todos los derechos reservados.",
        developed: "Desarrollado por",
        news_another: "Otras Noticias",

        ilha_0: "COMO LLEGAR",
        ilha_0_text_1: "En el corazón de la  Costa Verde, la posada esta ubicada en la Vila de Abraão, en Ilha Grande - Angra dos Reis - RJ.",
        ilha_0_text_2: "El acceso a la Isla solo puede ser realizado por barco partiendo desde: Angra dos Reis, Conceição de Jacareí o Mangaratiba.",

        ilha_1: "PLAYA  DE AVENTUREIRO",
        ilha_1_text_1: "La playa de Aventureiro según muchas opiniones es el lugar más paradisíaco de Ilha Grande con paisajes deslumbrantes. Soporta décadas sin cambiar sus características. Lugar para escuchar el sonido de las olas y disfrutar de la luz de la linterna y las velas o la luz de la luna. Aquí, el tiempo parece haber parado casi lo que notamos al observar su modesto pueblo de pescadores que guarda gran parte de la cultura local, hoy con más de 100 habitantes.",
        ilha_1_text_2: "La playa Aventureiro tiene unos 500m de arena fina y plateada, fondo poco profundo y consistente, agua clara y mar agitado, excepto en el extremo izquierdo de los que llegan al mar, donde hay un pequeño muelle para amarrar. En el lado izquierdo de los que miran desde el mar, se encuentra el cocotero más famoso de Ilha Grande, el \"cocotero tendido\" es una postal de la playa de Aventureiro e Ilha Grande, también hay una pequeña piscina natural. Los aventureros son muy populares entre los surfistas que llegan en grupos y se alojan en campings \"caiçaras\" autorizados por la Ciudad de Angra dos Reis. Hay dos miradores en Aventureiro, Espia, en el lado izquierdo de quienes llegan al muelle, donde hay una escalera de cuerda que conduce a la cima del pico más alto en la punta del Aventureiro. El otro es el mirador de la Sundara al que se accede por un sendero cuesta arriba, que también sale cerca del muelle, está a unos 20 minutos cuesta arriba pero la vista vale la pena, cubriendo gran parte de la costa suroeste de Ilha Grande. La playa vecina, una extensión de Aventureiro se llama playa de demostración, esta sin vivienda. ",
        ilha_1_text_3: "Su nombre tiene sentido porque no siempre es fácil llegar a Praia do Aventureiro. Cuando el mar tiene resaca no es recomendable navegar, atravesando las “temibles” puntas de Drago y Meros. En estas circunstancias, la única forma de llegar a Aventureiro es caminando desde el pueblo de Provetá. ",
        ilha_2: "PLAYA LOPES MENDES",
        ilha_2_text_1: "Una de las playas más hermosas de toda la costa brasileña se encuentra en Ilha Grande y se llama Lopes Mendes, ubicada en la costa del océano, orientada al sur, con aproximadamente 3 km de arena blanca, muy fina y firme, ya se tiene muchas décadas, como la más famosa de todas las Ilha Grande. El mar de Lopes Mendes siempre parece agitado, apto para la práctica del surf, por lo que es la playa donde más se practica este deporte. Es una playa poco profunda y la transparencia del agua es impresionante, así como su variación de matices. ",
        ilha_2_text_2: "Lopes Mendes ya estaba muy habitado por la comunidad de Caiçara, cuando la Colonia Penal de Dois Ríos experimentó su apogeo, pero hoy es una playa casi desierta con mucha sombra natural de los almendros que hay en su borde. ",
        ilha_3: "PLAYA DOS RÍOS",
        ilha_3_text_1: "La playa de Dois Rios es fascinante, sus arenas amarillas tienen aproximadamente un kilómetro de largo. El resplandor azul verdoso de las aguas y las olas que se forman con el viento del mar abierto golpean la playa invitándonos a nadar. Es propicio para la práctica del surf y la pesca ya que está frente al mar abierto, la región está bien servida con pescado de calidad. ",
        ilha_3_text_2: "Reconocido como uno de los lugares más bellos de Ilha Grande, este impresionante lugar fue el eje de la fama de “Ilha Presídio”. Esta situación fue, quizás, la principal responsable de la preservación de Ilha Grande porque inhibió la actividad turística hasta 1993. El sitio albergaba la extinta prisión de Cândido Mendes. ",
        ilha_3_text_3: "Hoy la propiedad del Estado de Río de Janeiro está siendo administrada por la Universidad Estatal de Río de Janeiro - UERJ, que creó un Centro de Estudios Ambientales y Desarrollo Sostenible - CEADS.",
        ilha_3_text_4: "El pueblo de Dois Ríos da la bienvenida a unos 150 residentes, de los cuales una buena parte son empleados de la UERJ que ocupan las antiguas casas estándar, ahora en mal estado. Es del pueblo de Dois Rios donde se vende pescado casi a diario, que se vende en Vila do Abraão.",
        ilha_4: "PLAYA PARNAIOCA",
        ilha_4_text_1: "La hermosa Praia da Parnaioca es una de las playas más aisladas de toda Ilha Grande. Frente al Atlántico con su arena amarillenta y gruesa, el mar es agitado, profundo y con poca sombra en sus 1.000 m de longitud. En el extremo oeste fluye el río Parnaioca, que es un encanto, formando una pequeña laguna en la playa. En el apogeo de Parnaioca, su río ya era mucho más voluminoso, al punto que era imposible cruzarlo en épocas de fuertes lluvias.",
        ilha_4_text_2: "No hay suministro de electricidad en Parnaioca. En las zonas de acampada la energía la proporciona un generador eléctrico. La señal celular es demasiado débil o inexistente. Un lugar para \"desconectarse\" en total interacción con la naturaleza, sin las distracciones de la modernidad. ",
        ilha_4_text_3: "Las ruinas de antiguas construcciones realizadas por manos de los esclavos y la pequeña Iglesia del Sagrado Corazón de Jesús aún hoy mantienen sus características originales. Este es uno de los pocos vestigios de la gran comunidad que vivió aquí. Quienes la conocen hoy nunca podrán imaginar que a mediados del siglo XX alguna vez fue la playa más poblada de Ilha Grande, albergando una comunidad de más de 1,000 personas que vivían de la pesca y la agricultura. Actualmente es una playa casi desierta de tan solo 5 habitantes.",
        ilha_5: "PLAYA LAGUNA AZUL",
        ilha_5_text_1: "Lagoa Azul es una piscina natural con fondo arenoso, algunas rocas y agua predominantemente azul, ubicada entre dos islotes en la importante región histórica de Freguesia de Santana. El sitio es un agregado geográfico de Ilha dos Macacos, cuyo extremo norte es el punto de Ilha Grande más cercano al continente. La región es hermosa con rincones dignos de llamar paraíso. Está muy cerca de otras playas sensacionales, Grumixama, Baleia, Parroquia Sur y Norte. ",
        ilha_5_text_2: "Lagoa Azul, apodo turístico inspirado en el cine clásico de los 80 con la actriz Brooke Shieldes, es uno de los puntos turísticos más populares de Ilha Grande. Los botes están anclados y los visitantes pueden bajar a nadar, flotar con “fideos” (flotadores de espuma) proporcionados por las tripulaciones, hacer snorkel o alimentar a los peces “sargentinhos” (nombre científico abdufdef) con pan rallado, ya adictos a ser alimentados. por los visitantes. En pleno verano, la región se llena de lanchas rápidas, veleros, goletas.",
        ilha_6: "PLAYA  NEGRA",
        ilha_6_text_1: "La playa negra con sus arenas oscuras (monazitic) es un punto de interés geológico en PEIG - Ilha Grande State Park. Es la segunda y última playa del circuito Abraão - lado Lazareto, a la izquierda de los que están en Vila do Abraão mirando al mar. La playa negra es muy popular en todas las épocas del año, ofrece sombra bajo los árboles y en ella desemboca un río que forma un pequeño lago de agua fresca y fría, donde puedes bañarte y nadar. ¡A los niños les encanta!",
        ilha_6_text_2: "La playa negra se divide en dos partes, antes y después de las ruinas de la antigua prisión. La primera está junto a la carretera que da acceso a la playa, donde hay un estanque de agua dulce y la parte final de la playa, la más reservada, con mayor opción de sombra.",
        ilha_6_text_3: "El negro es una buena opción para hacer snorkel, nadar, leer un libro, relajarse después del almuerzo, escuchar el canto de los pájaros. Desde aquí, puede seguir el sendero que conduce al acueducto y la poción, donde comienza el sendero T2, que conduce a Star Cove. ",
        ilha_6_text_4: "Las ruinas de la antigua prisión y Lazareto.",
        ilha_6_text_5: "En la parte central de la playa negra se encuentran las ruinas de un enorme complejo de pabellones que funcionó desde 1886-1913 como hospital para la cuarentena de inmigrantes extranjeros, ubicación elegida por DOM PEDRO II. En 1939 fue utilizado por el cuerpo de infantes de marina y de 1940 a 1954 sirvió como prisión para los presos comunes, de la cual solo hay un gran muro de piedra que se eleva sobre la arena de la playa, cubierto de vegetación sobre una losa de hormigón, debajo del cual se encuentran algunas celdas de la prisión. Algunas otras paredes aisladas se pueden ver dentro de los bosques cercanos. El sitio está actualmente cerrado, en riesgo de colapso.",
        ilha_7: "PLAYA DE ABRAÃOZINHO",
        ilha_7_text_1: "Este es el sexto y último circuito de Abraão, se encuentra a unos 1.200 metros de la playa de Canto do Abraão, tiene aguas transparentes, cálidas y tranquilas con sombra y la facilidad de un pequeño bar. Tiene una extensión denominada Praia do Sobrado, a la que se puede acceder en la bifurcación del sendero a la playa de Abraãozinho, muy cerca de la llegada.",
        ilha_7_text_2: "Abraãozinho es una playa para ir por la mañana y volver por la tarde, permite bañarse en el mar, remar en kayaks y practicar snorkel en la costa.",
        ilha_7_text_3: "Desde la playa de Abraão, salen varios botes taxi que llevan a los visitantes a la playa de Abraãozinho y de regreso al pueblo.",

        link_checkin: "https://webcheckin.silbeck.com.br/recreiodapraia/es-es",

    }
}

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language');
        if (!storageLanguage) {
            setLanguageStore('pt');
        }else{
            setLanguageStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language', lang);
    }

    function setLanguageStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguage(languages.pt);
            setStorage("pt");
        }else if(lang==="en" || lang==="EN"){
            setLanguage(languages.en);
            setStorage("en");
        }else if(lang==="es" || lang==="ES"){
            setLanguage(languages.es);
            setStorage("es");
        }
    }

    useEffect(() => {
        loadStorage();
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, setLanguageStore }} >
            {children}
        </LanguageContext.Provider>
    )

}

export default LanguageContext;