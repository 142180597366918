import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import standard_1 from '../assets/images/standard.jpg';
import standard_2 from '../assets/images/standard-triplo.jpg';
import standard_3 from '../assets/images/standard-quadruplo.jpg';

import waves from '../assets/images/waves_blue.png';
import LanguageContext from '../contexts/language';

export default function Standard() {

    const { language } = useContext(LanguageContext);

    return (
        <section className="card-area padding-top-100px padding-bottom-90px text-center">
            <div className="container">
                <Fade top>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2 className="sec__title">{language.title_comfort} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade bottom cascade>
                    <div className="row mt-5">
                        <div className="col-lg-4 column-td-6">
                            <NavLink to="/standard">
                                <div className="card-item">
                                    <div className="card-image-wrap">
                                        <div className="card-image">
                                            <img src={standard_1} className="card__img" />
                                        </div>
                                    </div>
                                    <div className="card-content-wrap">
                                        <div className="card-content">
                                            <h4 className="card-title">{language.standard_1}</h4>
                                            <p className="card-sub">{language.standard_1_text}</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 column-td-6">
                            <NavLink to="/standard-triplo">
                                <div className="card-item">
                                    <div className="card-image-wrap">
                                        <div className="card-image">
                                            <img src={standard_2} className="card__img" />
                                        </div>
                                    </div>
                                    <div className="card-content-wrap">
                                        <div className="card-content">
                                            <h4 className="card-title">{language.standard_2}</h4>
                                            <p className="card-sub">{language.standard_2_text}</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 column-td-6">
                            <NavLink to="/standard-quadruplo">
                                <div className="card-item">
                                    <div className="card-image-wrap">
                                        <div className="card-image">
                                            <img src={standard_3} className="card__img" />
                                        </div>
                                    </div>
                                    <div className="card-content-wrap">
                                        <div className="card-content">
                                            <h4 className="card-title">{language.standard_3}</h4>
                                            <p className="card-sub">{language.standard_3_text}</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        
                    </div>
                </Fade>
            </div>
        </section>
    );
}