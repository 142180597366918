import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import bestof_1 from '../assets/images/bestof_1.jpg';
import bestof_2 from '../assets/images/bestof_2.jpg';
import bestof_3 from '../assets/images/bestof_3.jpg';
import bestof_4 from '../assets/images/bestof_4.jpg';
import bestof_5 from '../assets/images/bestof_5.jpg';
import bestof_6 from '../assets/images/bestof_6.jpg';

import waves from '../assets/images/waves_blue.png'; 
import LanguageContext from '../contexts/language';

export default function BestOfYou() {

    const { language } = useContext(LanguageContext);

    function Content(props){
        return (
            <div className="col-md-4 column-td-6">
                <NavLink to="/galeria">
                    <div className="bestof-item">
                        <div className="bestof-img">
                            <img src={props.image} alt="bestof-img" />
                        </div>
                        <div className="bestof-content-normal">
                            <div className="bestof-bio">
                                <h4 className="bestof__title">{props.title}</h4>
                            </div>
                        </div>
                        <div className="bestof-content">
                            <div className="bestof-bio mb-3">
                                <h4 className="bestof__title">{props.title}</h4>
                                <span className="bestof__meta">{props.children}</span>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    }
    return (
        <section className="bestof-area padding-top-100px padding-bottom-70px">
            <div className="container">
                <Fade top>
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="section-heading text-center">
                                <h5 className="sec__top">{language.bestof_title}</h5>
                                <h2 className="sec__title">{language.bestof_subtitle} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                <p className="sec__desc">
                                {language.bestof_text_1}<br />
                                {language.bestof_text_2}
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="row mt-5">
                        <Content title={language.bestof_1} image={bestof_1}>
                        {language.bestof_1_text}
                        </Content>
                        <Content title={language.bestof_2} image={bestof_2}>
                        {language.bestof_2_text}
                        </Content>
                        <Content title={language.bestof_3} image={bestof_3}>
                        {language.bestof_3_text}
                        </Content>
                        <Content title={language.bestof_4} image={bestof_4}>
                        {language.bestof_4_text}
                        </Content>
                        <Content title={language.bestof_5} image={bestof_5}>
                        {language.bestof_5_text}
                        </Content>
                        <Content title={language.bestof_6} image={bestof_6}>
                        {language.bestof_6_text}
                        </Content>
                    </div>
                </Fade>
            </div>
        </section>
    );
}