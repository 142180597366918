import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pulse from 'react-reveal/Pulse';

import banner_1 from '../assets/images/ilha-grande/banner_1.jpg';
import banner_2 from '../assets/images/ilha-grande/banner_2.jpg';
import banner_3 from '../assets/images/ilha-grande/banner_3.jpg';
import banner_4 from '../assets/images/ilha-grande/banner_4.jpg';
import banner_5 from '../assets/images/ilha-grande/banner_5.jpg';
import banner_6 from '../assets/images/ilha-grande/banner_6.jpg';
import banner_7 from '../assets/images/ilha-grande/banner_7.jpg';
import banner_8 from '../assets/images/ilha-grande/banner_8.jpg';
import banner_9 from '../assets/images/ilha-grande/banner_9.jpg';
import banner_10 from '../assets/images/ilha-grande/banner_10.jpg';

export default function HeaderNormalIlha(props) {
    const settings = {
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000
    };

    let banners = [
        banner_1,
        banner_2,
        banner_3,
        banner_4,
        banner_5,
        banner_6,
        banner_7,
        banner_8,
        banner_9,
        banner_10,
    ];

    return (

        <section className="breadcrumb-area">
            
                <div className="breadcrumb-wrap">
                    <div className="breadcrumb-content">
                        <Pulse>
                            <div>
                                <h2 className="breadcrumb__title">{props.title}</h2>
                                { props.subtitle &&
                                <ul className="breadcrumb__list">
                                    <li>{props.subtitle}</li>
                                </ul>}
                            </div>
                        </Pulse>
                    </div>
                </div>
            
            <Slider {...settings}>
                {banners.slice(0, 6).map(item => (
                    <div key={item}><div className="breadcrumb-area-bg" style={{backgroundImage: `url(${item})`}}></div></div>
                ))}
            </Slider>
        </section>
        
    );
}