import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Pousada from './pages/Pousada';
import Galeria from './pages/Galeria';
import Novidades from './pages/Novidades';
import NovidadesPost from './pages/NovidadesPost';
import CheckIn from './pages/CheckIn';

import Standard from './pages/Standard';
import StandardTriplo from './pages/StandardTriplo';
import StandardQuadruplo from './pages/StandardQuadruplo';
import IlhaGrande from './pages/IlhaGrande';
import LinkMap from './pages/LinkMap';
import Page404 from './pages/Page404';

import ScrollToTop from './components/ScrollToTop';

export default function Routes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/sobre-nos" component={Sobre} />
                <Route path="/a-pousada" component={Pousada} />
                <Route path="/check-in" component={CheckIn} />
                <Route path="/galeria" component={Galeria} />
                <Route path="/novidades" component={Novidades} />
                <Route path="/standard" component={Standard} />
                <Route path="/standard-triplo" component={StandardTriplo} />
                <Route path="/standard-quadruplo" component={StandardQuadruplo} />
                <Route path="/ilha-grande" component={IlhaGrande} />
                <Route path="/novidade/:url" exact component={NovidadesPost} />
                <Route path="/linkmap" component={LinkMap} />
                <Redirect from="/redirect/:url" to="/novidade/:url" />
                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    );
}