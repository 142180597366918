import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Pulse from 'react-reveal/Pulse';
import { Parallax } from 'react-parallax';
import banner from '../assets/images/banner_ilhagrande.jpg';

import waves from '../assets/images/waves.png';
import LanguageContext from '../contexts/language';


export default function IlhaGrande() {

    const { language } = useContext(LanguageContext);
    
    return (
        <Parallax strength={300} bgImage={banner} className="ilhagrande-area">
            <div className="section-heading text-center">
                <Pulse>
                    <h2 className="sec__title text-white">ILHA GRANDE <img src={waves} style={{width:55, verticalAlign: 'text-top'}} /></h2>
                    <p className="sec__desc">
                        {language.ilha_grade_text_1}
                    </p>
                    <div className="btn-box margin-top-40px">
                        <Link className="theme-btn" to="/ilha-grande">{language.mais_infos}</Link>
                    </div>
                </Pulse>
            </div>
        </Parallax>
    );
}