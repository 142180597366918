import React from 'react';
import './global.css';
import './styles.css';

import { LanguageProvider } from './contexts/language';
import Routes from './routes';

function App() {
    return (
        <LanguageProvider>
            <Routes />
        </LanguageProvider>
    );
}

export default App;