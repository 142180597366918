import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import logo from '../assets/images/logo.png';

import LanguageContext from '../contexts/language';

export default function Header() {

    const { language, setLanguageStore } = useContext(LanguageContext);

    function setLanguage(lang) {
        setLanguageStore(lang);
        setMenu(false);
    }

    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);
    const [menu, setMenu] = useState(false);

    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        }
    }, []);

    function toggleMenu(){
        menu ? setMenu(false) : setMenu(true)
    }

    return (
        <header className="header-area" style={{ marginTop: sticky.offset }}>
            <div className={`header-menu-wrapper ${sticky.isSticky ? 'header-fixed' : ''}`} ref={headerRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="menu-full-width">
                                <div className="logo">
                                    <NavLink activeClassName="active" to="/"><img src={logo} alt="logo" /></NavLink>
                                </div>
                                <div className="logo-right-content" onClick={toggleMenu}>
                                    <div className="side-menu-open">
                                        <span className="menu__bar"></span>
                                        <span className="menu__bar"></span>
                                        <span className="menu__bar"></span>
                                    </div>
                                </div>
                                <div className="main-menu-content">
                                    <nav>
                                        <ul>
                                            <li>
                                                <NavLink exact activeClassName="active" to="/">{language.menu_home}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink activeClassName="active" to="/a-pousada">{language.menu_pousada}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink activeClassName="active" to="/ilha-grande">ILHA GRANDE</NavLink>
                                            </li>
                                            <li>
                                                <a href={language.link_checkin} target="_blank">{language.menu_checkin}</a>
                                            </li>
                                            <li>
                                                <a href="https://sbreserva.silbeck.com.br/recreiodapraia" target="_blank">{language.menu_reservas}</a>
                                            </li>
                                            {/*<li>
                                                <NavLink activeClassName="active" to="/galeria">{language.menu_galeria}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink activeClassName="active" to="/novidades">{language.menu_novidades}</NavLink>
                                            </li>*/}
                                            <li>
                                                <a onClick={() =>{}} className="arrow">
                                                    <div>{language.language_sigla}</div>
                                                    <div className="lang-arrow"><HiChevronDown size={14} /></div>
                                                </a>
                                                <div className="account-lang">
                                                    <a onClick={() => {setLanguage('pt')}}>
                                                        {language.language_pt_inverse}
                                                    </a>
                                                    <a onClick={() => {setLanguage('en')}}>
                                                        {language.language_en_inverse}
                                                    </a>
                                                    <a onClick={() => {setLanguage('es')}}>
                                                        {language.language_es_inverse}
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`side-nav-container ${menu && 'active'}`}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={toggleMenu}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li>
                            <NavLink exact activeClassName="active" to="/">{language.menu_home}</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/a-pousada">{language.menu_pousada}</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/ilha-grande">ILHA GRANDE</NavLink>
                        </li>
                        <li>
                            <a href={language.link_checkin} target="_blank">{language.menu_checkin}</a>
                        </li>
                        <li>
                            <a href="https://sbreserva.silbeck.com.br/recreiodapraia" target="_blank">{language.menu_reservas}</a>
                        </li>
                        {/*<li>
                            <NavLink activeClassName="active" to="/galeria">{language.menu_galeria}</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/novidades">{language.menu_novidades}</NavLink>
                        </li>*/}
                        <li>
                            <a className="text-uppercase" onClick={() => {setLanguage('pt')}}>{language.language_pt_inverse}</a>
                        </li>
                        <li>
                            <a className="text-uppercase" onClick={() => {setLanguage('en')}}>{language.language_en_inverse}</a>
                        </li>
                        <li>
                            <a className="text-uppercase" onClick={() => {setLanguage('es')}}>{language.language_es_inverse}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}