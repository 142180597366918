import React, { useState, useEffect } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import Fade from 'react-reveal/Fade';
import api from '../services/api';
import Shimmer from './Shimmer';

export default function Gallery(props) {

    const [gallery, setGallery] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage(){
        const response = await api.get(`/gallery`);
        setGallery(response.data.content);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, []);

    function ContentLoader(){
        return (
            <div className="col-md-4 column-td-6">
                <div className="bestof-300">
                    <Shimmer />
                </div>
            </div>
        )
    }

    function Content(props){
        return (
            <div className="col-md-4 column-td-6">
                <Fade bottom cascade>
                    <div className="bestof-item">
                        <div className="bestof-img">
                            <img src={props.image} />
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }

    return (
        <section className="bestof-area padding-top-100px padding-bottom-70px">
            <div className="container">
                {
                    loading &&
                    <div className="row mt-5">
                        <ContentLoader />
                        <ContentLoader />
                        <ContentLoader />
                        <ContentLoader />
                        <ContentLoader />
                        <ContentLoader />
                    </div>
                }
                <SRLWrapper >
                    <div className="row mt-5">
                        {gallery.map(item => (
                            <Content key={item.id} image={item.image} />
                        ))}
                    </div>
                </SRLWrapper>
                
            </div>
        </section>
    );
}