import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderNormal from '../../components/HeaderNormal';
import BestOfYou from '../../components/BestOfYou';
import LanguageContext from '../../contexts/language';
import Banner3 from '../../components/Banner3';

import sobre from '../../assets/images/sobre_2.jpg';
import base from '../../assets/images/blank-850.png';
import pousada_1 from '../../assets/images/pousada_1.jpg';
import pousada_2 from '../../assets/images/pousada_2.jpg';
import pousada_3 from '../../assets/images/pousada_3.jpg';
import pousada_4 from '../../assets/images/pousada_4.jpg';
import pousada_5 from '../../assets/images/pousada_5.jpg';
import pousada_6 from '../../assets/images/pousada_6.jpg';
import pousada_7 from '../../assets/images/pousada_7.jpg';
import pousada_8 from '../../assets/images/pousada_8.jpg';
import pousada_9 from '../../assets/images/pousada_9.jpg';
import pousada_10 from '../../assets/images/pousada_10.jpg';
import pousada_11 from '../../assets/images/pousada_11.jpg';
import pousada_12 from '../../assets/images/pousada_12.jpg';
import waves from '../../assets/images/waves_blue.png';

export default function Pousada() {

    const { language } = useContext(LanguageContext);

    function ContentLeft(props){
        return (
            <div className="row separator">
                <div className="col-lg-6">
                    <div className="about-content">
                       <div className="section-heading">
                            <h2 className="sec__title">{props.title}</h2>
                            <p className="sec__desc font-size-17 pr-3 mb-3">
                                {props.children}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="img-boxes">
                        <div className="row">
                            <Fade left>
                                <div className="col-lg-8 column-td-6">
                                    <div className="img-box-item mt-4">
                                        <img src={base} style={{backgroundImage: `url(${props.image_1})`}} className="cover-bg" />
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-lg-4 column-td-6">
                                    <div className="img-box-item">
                                        <img src={base} style={{backgroundImage: `url(${props.image_2})`}} className="cover-bg" />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ContentRight(props){
        return (
            <div className="row separator">
                <div className="col-lg-6">
                    <div className="img-boxes">
                        <div className="row">
                            <Fade left>
                                <div className="col-lg-8 column-td-6">
                                    <div className="img-box-item mt-4">
                                        <img src={base} style={{backgroundImage: `url(${props.image_1})`}} className="cover-bg" />
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-lg-4 column-td-6">
                                    <div className="img-box-item">
                                        <img src={base} style={{backgroundImage: `url(${props.image_2})`}} className="cover-bg" />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about-content">
                        <div className="section-heading">
                            <h2 className="sec__title">{props.title}</h2>
                            <p className="sec__desc font-size-17 pr-3 mb-3">
                                {props.children}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <HeaderNormal title={language.menu_pousada} />

            <BestOfYou />

            <section className="about-area bg-about padding-top-100px padding-bottom-100px" style={{backgroundImage: `url(${sobre})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-heading">
                                    <Fade top cascate>
                                        <h5 className="sec__top">{language.page_sobre_title}</h5>
                                        <h2 className="sec__title">{language.page_sobre_subtitle} <img src={waves} style={{width:50, verticalAlign: 'bottom'}} /></h2>
                                    </Fade>
                                    <Fade bottom>
                                        <p className="sec__desc font-size-17 text-justify pb-4">
                                        {language.page_sobre_text_1}
                                        </p>
                                        <p className="sec__desc font-size-17 text-justify pb-4">
                                        {language.page_sobre_text_2}
                                        </p>
                                        <p className="sec__desc font-size-17 text-justify pb-4">
                                        {language.page_sobre_text_3}
                                        </p>
                                        <p className="sec__desc font-size-17 text-justify pb-4">
                                        {language.page_sobre_text_4}
                                        </p>
                                        <p className="sec__desc font-size-17 text-justify pb-4">
                                        {language.page_sobre_text_5}
                                        </p>
                                    </Fade>
                                </div>
                                <Fade bottom>
                                    <div className="btn-box padding-top-30px">
                                        <a href="#contato" className="theme-btn">{language.btn_contact}</a>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            
                        </div>
                    </div>
                </div>
            </section>

            <Banner3 />

            <section className="about-area padding-top-40px padding-bottom-0px">
                <div className="container">
                    <Fade bottom cascate>
                        <ContentLeft title={language.sobre_pousada_1} image_1={pousada_1} image_2={pousada_2}>
                        {language.sobre_pousada_1_text}
                        </ContentLeft>

                        <ContentRight title={language.sobre_pousada_2} image_1={pousada_3} image_2={pousada_4}>
                        {language.sobre_pousada_2_text}
                        </ContentRight>

                        <ContentLeft title={language.sobre_pousada_3} image_1={pousada_5} image_2={pousada_6}>
                        {language.sobre_pousada_3_text}
                        </ContentLeft>

                        <ContentRight title={language.sobre_pousada_4} image_1={pousada_7} image_2={pousada_8}>
                        {language.sobre_pousada_4_text}
                        </ContentRight>

                        <ContentLeft title={language.sobre_pousada_5} image_1={pousada_9} image_2={pousada_10}>
                        {language.sobre_pousada_5_text}
                        </ContentLeft>

                        <ContentRight title={language.sobre_pousada_6} image_1={pousada_11} image_2={pousada_12}>
                        {language.sobre_pousada_6_text}<br />
                            <ul>
                                <li className="ml-4">• {language.sobre_pousada_6_text_1}</li>
                                <li className="ml-4">• {language.sobre_pousada_6_text_2}</li>
                                <li className="ml-4">• {language.sobre_pousada_6_text_3}</li>
                            </ul>
                        </ContentRight>
                    </Fade>

                </div>
            </section>

            <section className="hiw-area padding-top-40px padding-bottom-80px after-none text-left">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 column-td-6">
                            <div className="icon-box icon-box-layout">
                                <div className="info-content">
                                    <h4 className="info__title">{language.sobre_more_1}</h4>
                                    <p className="info__desc">
                                    {language.sobre_more_1_text}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 column-td-6">
                            <div className="icon-box icon-box-layout">
                                <div className="info-content">
                                    <h4 className="info__title">{language.sobre_more_2}</h4>
                                    <p className="info__desc">
                                    {language.sobre_more_2_text}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <Footer newsletter={true} />
        </div>
    );
}